import { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import { NAMESPACES } from '../../../i18n/constants';
import { useCategorySubscription } from '../../../hooks/rps/useCategorySubscription';
import { selectedCurrencySelector } from '../../../redux/selectors/commonSelector';
import { useAppSelector } from '../../../redux/hooks';
import { getCategories, getCategoriesSubscriptionState } from '../../../redux/selectors/rpsSelector';
import { SubscriptionsStates } from '../../../redux/reducers/rpsReducer/rpsReducer';

interface CategoriesSubscriptionProps {
    children: ReactElement;
    t: (key: string, options?: { ns: string }) => string;
}

export const CategoriesSubscription = ({ children, t }: CategoriesSubscriptionProps): ReactElement => {
    const currency = useAppSelector(selectedCurrencySelector);
    const categories = useAppSelector(getCategories);
    useCategorySubscription(currency);

    const categorySubscriptionLoading = useAppSelector(getCategoriesSubscriptionState) === SubscriptionsStates.LOADING;
    const categorySubscriptionError = useAppSelector(getCategoriesSubscriptionState) === SubscriptionsStates.ERROR;
    const categorySubscriptionSuccess = useAppSelector(getCategoriesSubscriptionState) === SubscriptionsStates.SUCCESS;

    const handleRefresh = () => {
        window.location.reload();
    };

    if (categorySubscriptionLoading) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('lobby.category-subscription.loading')}</h6>
            </Row>
        );
    }

    if (categorySubscriptionError) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('lobby.category-subscription.error')}</h6>
                <Button
                    className="shadow-none error-page__refresh-button"
                    variant="secondary"
                    onClick={handleRefresh}
                >
                    {t('global.errorBoundaryFallback.button')}
                </Button>
            </Row>
        );
    }

    if (categorySubscriptionSuccess && !categories[currency.toUpperCase()]) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('lobby.category-subscription.empty-categories')}</h6>
                <Button
                    className="shadow-none"
                    variant="info"
                    onClick={() => console.log('Implement demo action')}
                >
                    {t('lobby.category-subscription.play-demo')}
                </Button>
            </Row>
        );
    }

    return categorySubscriptionSuccess && children;
};

export default withTranslation([NAMESPACES.RPS])(CategoriesSubscription);
