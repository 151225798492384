import { RootState } from '../store';

export const getCustomTokenSelector = (state: RootState) => state.rps.customToken;

export const isPlayerWaitingForOpponentSelector = (state: RootState) => !state.rps.gameState;

export const getQueuePositionIdSelector = (state: RootState) => state.rps?.queuePositionId;
export const getQueuePositionSelector = (state: RootState) => state.rps?.queuePosition;
export const getQueuePositionSubscriptionState = (state: RootState) => state.rps.queuePositionSubscriptionState;

export const getGameStateSelector = (state: RootState) => state.rps.gameState;
export const getGameSubscriptionState = (state: RootState) => state.rps.gameStateSubscriptionState;

export const getCategoriesSubscriptionState = (state: RootState) => state.rps.categoriesSubscriptionState;
export const getCategories = (state: RootState) => state.rps.categories;

export const getIsPlayerInGame = (state: RootState) => state.rps.isUserInPlay;

export const getRenderingGameTime = (state: RootState) => state.rps.gameRendering.gameTime;
export const getRenderingPreviewHand = (state: RootState) => state.rps.gameRendering.previewHand;
