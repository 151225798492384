import { ReactElement } from 'react';

import ValidSession from './valid-session/ValidSession';
import RpsCustomAuthentication from './rps-custom-authentication/RpsCustomAuthentication';

interface RpsAuthenticationProps {
    children: ReactElement
}

export const RpsAuthentication = ({ children }: RpsAuthenticationProps): ReactElement => {
    return (
        <ValidSession>
            <RpsCustomAuthentication>
                {children}
            </RpsCustomAuthentication>
        </ValidSession>
    );
};

export default RpsAuthentication;
