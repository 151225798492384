import { ReactElement } from 'react';

import ErrorBoundaryFallback from './error-boundary-fallback/ErrorBoundaryFallback';
import ErrorBoundary from './error-boundary/GlobalErrorBoundary';
import GlobalHooks from './hooks/GlobalHooks';
import GlobalLoading from './loading/GlobalLoading';
import GlobalContainer from './global-container/GlobalContainer';
import GlobalLoadingHands from './global-loading-hands/GlobalLoadingHands';

import { useParentDataListener } from '../../hooks/iframe/useParentDataListener';

interface GlobalsProps {
    children: ReactElement
}

export const Globals = ({ children }: GlobalsProps): ReactElement => {
    useParentDataListener();

    return (
        <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
            <GlobalContainer>
                <GlobalLoadingHands>
                    <GlobalLoading>
                        <GlobalHooks>
                            {children}
                        </GlobalHooks>
                    </GlobalLoading>
                </GlobalLoadingHands>
            </GlobalContainer>
        </ErrorBoundary >
    );
};

export default Globals;
