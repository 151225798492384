
import { ReactElement, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { QueuePositionState } from '@cryptoskill/rps-contract';

import { NAMESPACES } from '../../../i18n';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getQueuePositionSelector } from '../../../redux/selectors/rpsSelector';
import { useRequestsState } from '../../../hooks/requestStates/useRequestsState';
import { fetchGameCancel } from '../../../redux/actions/rpsActions/rpsActions';
import { setCancelGame } from '../../../redux/reducers/rpsReducer/rpsReducer';
import { useRequestBot } from '../../../hooks/rps/useRequestBot';
import { useSendParentData } from '../../../hooks/iframe/core/useSendParentData';
import { POST_MESSAGE_TYPES } from '../../../constants/postMessagesTypes';

interface QueuePositionStatesHandlerProps {
    children: ReactElement,
    t: (key: string, options?: { ns: string }) => string;
}

export const QueuePositionStatesHandler = ({ children, t }: QueuePositionStatesHandlerProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { getIsAnyRequestPending, getHaveAllRequestsFulfilled } = useRequestsState();
    const queuePosition = useAppSelector(getQueuePositionSelector);
    const { sendMessageToParent } = useSendParentData();
    useRequestBot({ queuePosition });

    const memoizedChildren = useMemo(() => children, [children]);

    useEffect(() => {
        if (getHaveAllRequestsFulfilled(['fetchGameCancel']) && queuePosition.state === QueuePositionState.CANCELLED) {
            sendMessageToParent(POST_MESSAGE_TYPES.REFRESH_BALANCES);
            dispatch(setCancelGame());
        }
    }, [dispatch, getHaveAllRequestsFulfilled, queuePosition.state, sendMessageToParent]);

    /**
     * CANCELLED_WITH_RESERVATION to CANCELLED
     * Waiting for the unreservation then redirect to Lobby
     */
    if (queuePosition.state === QueuePositionState.CANCELLED_WITH_RESERVATION) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.queue-position-states-handler.cacelled-with-reservation')}</h6>
            </Row>
        );
    }

    if (getIsAnyRequestPending(['fetchGameCancel'])) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.queue-position-states-handler.cancelling-game')}</h6>
            </Row>
        );
    }

    /**
     * NOT_RESERVED to RESERVED
     * Waiting for the reservation
     */
    if (queuePosition.state === QueuePositionState.NOT_RESERVED) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.queue-position-states-handler.reserving-spot')}</h6>
                <Button
                    className='shadow-none'
                    variant='info'
                    onClick={() => dispatch(fetchGameCancel())}
                    disabled={getIsAnyRequestPending(['fetchGameCancel'])}
                >
                    {t('game.global.cancel-game')}
                </Button>
            </Row>
        );
    }

    /**
     * RESERVED but without gameId
     * Waiting for an opponent
     */
    if (!queuePosition.gameId) {
        sendMessageToParent(POST_MESSAGE_TYPES.REFRESH_BALANCES);
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.queue-position-states-handler.looking-for-an-opponent')}</h6>
                <Button
                    className='shadow-none'
                    variant='info'
                    onClick={() => dispatch(fetchGameCancel())}
                    disabled={getIsAnyRequestPending(['fetchGameCancel'])}
                >
                    {t('game.global.cancel-game')}
                </Button>
            </Row>
        );
    }

    return memoizedChildren;
};

export default withTranslation([NAMESPACES.RPS])(QueuePositionStatesHandler);
