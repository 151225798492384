import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DisplayReducerState {
    showLoadingHands: boolean;
    showTitle: boolean;
}

const initialState: DisplayReducerState = {
    showLoadingHands: true,
    showTitle: true
};

export const displayReducer = createSlice({
    name: 'display',
    initialState,
    reducers: {
        setShowLoadingHands: (state, action: PayloadAction<boolean>) => {
            state.showLoadingHands = action.payload;
        },
        setShowTitle: (state, action: PayloadAction<boolean>) => {
            state.showTitle = action.payload;
        }
    }
});

export const { setShowLoadingHands, setShowTitle } = displayReducer.actions;

export const displayReducerInitialState = displayReducer.getInitialState();

export default displayReducer.reducer;
