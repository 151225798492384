import { ReactElement, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getUserData } from '../../../redux/selectors/commonSelector';
import { fetchFirestoreActiveQueuePositions } from '../../../redux/actions/rpsActions/rpsActions';
import { useRequestsState } from '../../../hooks/requestStates/useRequestsState';
import { NAMESPACES } from '../../../i18n';

interface InGameVerifierProps {
    children: ReactElement,
    t: (key: string, options?: { ns: string }) => string;
}

export const InGameVerifier = ({ children, t }: InGameVerifierProps): ReactElement => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUserData);
    const {
        getIsAnyRequestPending,
        getHasAnyRequestFailed,
        getHaveAllRequestsFulfilled
    } = useRequestsState();

    useEffect(() => {
        dispatch(fetchFirestoreActiveQueuePositions(user.userId));
    }, [dispatch, user.userId]);

    if (getIsAnyRequestPending(['fetchFirestoreActiveQueuePositions'])) {
        return (
            <Row className='m-1 mb-2'>
                <h6 className='text-center'>{t('rps-game-router.in-game-verifier.loading')}</h6>
            </Row>
        );
    }

    return (getHasAnyRequestFailed(['fetchFirestoreActiveQueuePositions'])
        || getHaveAllRequestsFulfilled(['fetchFirestoreActiveQueuePositions'])
    ) && children;
};

export default withTranslation([NAMESPACES.RPS])(InGameVerifier);
