import { useEffect } from 'react';

import GameBody from '../../game/game-rendering/game-body/GameBody';
import { POST_MESSAGE_TYPES } from '../../../constants/postMessagesTypes';
import { HANDS_STATUS } from '../../../constants/hand';
import { useAppSelector } from '../../../redux/hooks';
import {
    getCurrentPlayerHand,
    getCurrentPlayerLoading,
    getCurrentPlayerPreviewHand,
    getCurrentPlayerStatus,
    getOpponentHand,
    getOpponentLoading,
    getOpponentStatus
} from '../../../redux/selectors/demoRpsSelector';

export const DemoGameBodyController = () => {
    const currentPlayerLoading = useAppSelector(getCurrentPlayerLoading);
    const currentPlayerHand = useAppSelector(getCurrentPlayerHand);
    const playerStatus = useAppSelector(getCurrentPlayerStatus);
    const previewHand = useAppSelector(getCurrentPlayerPreviewHand);

    const opponentLoading = useAppSelector(getOpponentLoading);
    const opponentHand = useAppSelector(getOpponentHand);
    const opponentStatus = useAppSelector(getOpponentStatus);

    const player1Hand = playerStatus === HANDS_STATUS.preview ? previewHand : currentPlayerHand;

    useEffect(() => {
        if (playerStatus === HANDS_STATUS.winner) {
            window.parent.postMessage({ type: POST_MESSAGE_TYPES.DISPATCH_CONFETTIS }, '*');
        }
    }, [playerStatus]);

    return (
        <GameBody
            shouldLoadPlayer1={currentPlayerLoading}
            statusPlayer1={playerStatus}
            showHandPlayer1={player1Hand}
            shouldLoadPlayer2={opponentLoading}
            statusPlayer2={opponentStatus}
            showHandPlayer2={opponentHand}
        />
    );
};

export default DemoGameBodyController;