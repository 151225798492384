import { Component } from 'react';

interface GlobalErrorBoundaryProps {
    children: React.ReactNode;
    fallback: React.ReactNode;
}

interface GlobalErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<GlobalErrorBoundaryProps, GlobalErrorBoundaryState> {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    render() {
        const { hasError } = this.state;
        const { children, fallback } = this.props;

        if (hasError) {
            return fallback;
        }

        return children;
    }
}

export default ErrorBoundary;
