import { getFlagIconLink } from '../util/urlBuilder';

export enum LANGS {
    EN = 'en'
}

type LangConfig = {
    nativeName: string;
    flagIconLink: string;
};

export const LANGS_CONFIG: Record<string, LangConfig> = {
    [LANGS.EN]: {
        nativeName: 'English',
        flagIconLink: getFlagIconLink(LANGS.EN)
    }
};

export enum NAMESPACES {
    RPS = 'rps'
}
