import { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { NAMESPACES } from '../../../../i18n';

export interface TableHeaderElementProps {
    value: string;
    t: (key: string) => string;
}

const TableHeaderElement = ({ value, t }: TableHeaderElementProps): ReactElement => {
    switch (value) {
        case 'ACTIVE_PLAYERS_LABEL':
            return (
                <th key={value}>
                    <Row xs="auto" className='flex-nowrap'>
                        <Col className='p-1'>
                            <div className="dot-container"><span className="dot" /></div>
                        </Col>
                        <Col className='p-1'>
                            {t('lobby.table.headers.active-players')}
                        </Col>
                    </Row>
                </th>
            );
        default:
            return (
                <th key={value}>
                    {t(`lobby.table.headers.${value}`)}
                </th>
            );
    }
};

export default withTranslation([NAMESPACES.RPS])(TableHeaderElement);