import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

export const isUserCompletelyLogged = (state: RootState) => state.common.isUserCompletelyLogged;

export const getWaitingForParentMetadata = (state: RootState) => state.common.waitingForParentMetadata;

export const isUserSignedWithCustomToken = (state: RootState) => state.common.isUserSignedWithCustomToken;

export const getUserData = createSelector(
    (state: RootState) => state.common.userId,
    (state: RootState) => state.common.username,
    (state: RootState) => state.common.activated,
    (userId, username, activated) => ({
        userId,
        username,
        activated
    })
);

export const selectedCurrencySelector = (state: RootState) => state.common.selectedCoin;