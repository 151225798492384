import CategoriesSubscription from './categories-susbcription/CategoriesSubscription';
import ActiveUsersByCategoriesScheduler from './active-users-by-categories-scheduler/ActiveUsersByCategoriesScheduler';
import LobbyTable from './lobby-table/LobbyTable';

export const Lobby = () => {
    return (
        <CategoriesSubscription>
            <ActiveUsersByCategoriesScheduler>
                <LobbyTable />
            </ActiveUsersByCategoriesScheduler>
        </CategoriesSubscription>
    );
};

export default Lobby;