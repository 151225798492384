import { useEffect } from 'react';
import { QueuePositionData, QueuePositionState } from '@cryptoskill/rps-contract';

import { useAppDispatch } from '../../redux/hooks';
import { fetchRequestBot } from '../../redux/actions/rpsActions/rpsActions';

interface UseRequestBotProps {
    queuePosition: QueuePositionData;
}

export const useRequestBot = ({ queuePosition }: UseRequestBotProps) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (queuePosition.state === QueuePositionState.RESERVED && !queuePosition.gameId) {
            const interval = setInterval(() => {
                if (!queuePosition.gameId) {
                    dispatch(fetchRequestBot());
                }
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [dispatch, queuePosition.gameId, queuePosition.state]);
};