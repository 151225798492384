import { ReactElement, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GameState, QueuePositionState } from '@cryptoskill/rps-contract';
import Button from 'react-bootstrap/Button';

import { NAMESPACES } from '../../../i18n';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getGameStateSelector, getQueuePositionSelector } from '../../../redux/selectors/rpsSelector';
import { fetchGameJoin } from '../../../redux/actions/rpsActions/rpsActions';
import { clearGameState, clearQueuePositionData } from '../../../redux/reducers/rpsReducer/rpsReducer';
import { useRequestsState } from '../../../hooks/requestStates/useRequestsState';
import { useSendParentData } from '../../../hooks/iframe/core/useSendParentData';
import { POST_MESSAGE_TYPES } from '../../../constants/postMessagesTypes';

interface GameStatesHandlerProps {
    children: ReactElement;
    t: (key: string, options?: { ns: string }) => string;
}

export const GameStatesHandler = ({ children, t }: GameStatesHandlerProps): ReactElement => {
    const dispatch = useAppDispatch();
    const game = useAppSelector(getGameStateSelector);
    const queuePosition = useAppSelector(getQueuePositionSelector);
    const { getIsAnyRequestPending } = useRequestsState();
    const { sendMessageToParent } = useSendParentData();

    const memoizedChildren = useMemo(() => children, [children]);

    if (game.state === GameState.TIMEOUT) {
        if (queuePosition.state === QueuePositionState.UNRESERVED) {
            sendMessageToParent(POST_MESSAGE_TYPES.REFRESH_BALANCES);

            return (
                <Row className="m-2">
                    <Row className='text-center'>
                        <h6 className='text-center'>{t('game.game-states-handler.timeout')}</h6>
                    </Row>
                    <Col className='m-1'>
                        <Button
                            className='shadow-none w-100'
                            variant='info'
                            onClick={() => {
                                dispatch(clearQueuePositionData());
                                dispatch(clearGameState());
                            }}
                        >
                            {t('game.game-states-handler.return-lobby')}
                        </Button>
                    </Col>
                    <Col className='m-1'>
                        <Button
                            className='shadow-none w-100'
                            variant='info'
                            disabled={getIsAnyRequestPending(['fetchGameJoin'])}
                            onClick={() => dispatch(fetchGameJoin({ categoryName: game.category, categoryLevel: game.level }))}
                        >
                            {t('game.game-states-handler.rematch')}
                        </Button>
                    </Col>
                </Row>
            );
        }

        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.game-states-handler.unreserving')}</h6>
            </Row>
        );
    }

    return memoizedChildren;
};

export default withTranslation([NAMESPACES.RPS])(GameStatesHandler);