import { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

import { NAMESPACES } from '../../../../i18n';
import { useAppDispatch } from '../../../../redux/hooks';
import LobbyTableHeaderElement from '../lobby-table-header-element/LobbyTableHeaderElement';
import { setInDemoGame } from '../../../../redux/reducers/demoRpsReducer/demoRpsReducer';

export interface LobbyTableHeaderProps {
    headerValues: string[];
    t: (key: string) => string;
}

const LobbyTableHeader = ({ headerValues, t }: LobbyTableHeaderProps): ReactElement => {
    const dispatch = useAppDispatch();

    const handleDemoGameClick = () => {
        dispatch(setInDemoGame(true));
    };

    return (
        <thead>
            <tr className='align-middle'>
                {headerValues.map(headerValue => (
                    <LobbyTableHeaderElement key={headerValue} value={headerValue} />
                ))}
                <th key="demo-button" className='p-1'>
                    <Button
                        className="shadow-none w-100"
                        variant="outline-info"
                        onClick={handleDemoGameClick}
                    >
                        {t('lobby.table.buttons.demo')}
                    </Button>
                </th>
            </tr>
        </thead>
    );
};

export default withTranslation([NAMESPACES.RPS])(LobbyTableHeader);