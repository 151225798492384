import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';

import { NAMESPACES } from '../../../i18n/constants';

interface ErrorBoundaryFallbackProps {
    t: (key: string) => string;
}

export const ErrorBoundaryFallback = ({ t }: ErrorBoundaryFallbackProps) => {
    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className="error-page">
            <h1 className="error-page__title">{t('global.errorBoundaryFallback.title')}</h1>
            <p className="error-page__info">{t('global.errorBoundaryFallback.description')}</p>
            <Button
                className="shadow-none error-page__refresh-button"
                variant="secondary"
                onClick={handleRefresh}
            >
                {t('global.errorBoundaryFallback.button')}
            </Button>
        </div>
    );
};

export default withTranslation([NAMESPACES.RPS])(ErrorBoundaryFallback);
