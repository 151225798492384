import { ReactElement } from 'react';

import { selectedCurrencySelector } from '../../../redux/selectors/commonSelector';
import { useAppSelector } from '../../../redux/hooks';
import { getCategories } from '../../../redux/selectors/rpsSelector';
import { useActiveUsersByCategoriesScheduler } from '../../../hooks/rps/useActiveUsersByCategoriesScheduler.ts';

interface ActiveUsersByCategoriesSchedulerProps {
    children: ReactElement;
}

export const ActiveUsersByCategoriesScheduler = ({ children }: ActiveUsersByCategoriesSchedulerProps): ReactElement => {
    const selectedCurrency = useAppSelector(selectedCurrencySelector);
    const categories = useAppSelector(getCategories);
    const levels = categories[selectedCurrency.toUpperCase()].levels;
    useActiveUsersByCategoriesScheduler(selectedCurrency, levels);

    return children;
};

export default ActiveUsersByCategoriesScheduler;
