import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    CustomTokenResponse,
    JoinGameRequestBody,
    PlayHandRequestBody,
    JoinGameResponseBody,
    PlayHandResponseBody,
    CancelGameResponseBody,
    BotRequestResponse
} from '@cryptoskill/rps-contract';
import { collection, getCountFromServer, getDocs, query, where } from 'firebase/firestore';

import { rpssFirestore } from '../../../firebase/firebase';

export const fetchCustomToken = createAsyncThunk('fetchCustomToken',
    (_, thunkApi) => axios
        .get<CustomTokenResponse>(`${process.env.REACT_APP_BASE_RPSS_URL}/api/v1/custom-token/generate`)
        .then(response => response.data)
        .catch(thunkApi.rejectWithValue));

export const fetchFirestoreActiveQueuePositions = createAsyncThunk('fetchFirestoreActiveQueuePositions',
    (userId: string, thunkApi) => {
        const collectionRef = collection(rpssFirestore, 'queuePositions');

        const queryRef = query(
            collectionRef,
            where('active', '==', true),
            where('playerId', '==', userId)
        );

        return getDocs(queryRef)
            .then(querySnapshot => querySnapshot.docs.map(doc => doc.data()))
            .catch(thunkApi.rejectWithValue);
    }
);

export const fetchFirestoreActiveUserByCategories = createAsyncThunk('fetchFirestoreActiveUserByCategories',
    async (body: { selectedCurrency: string, levels: string[] }, thunkApi) => {
        const { selectedCurrency, levels } = body;

        if (!selectedCurrency || levels.length === 0) return;

        const collectionRef = collection(rpssFirestore, 'queuePositions');
        const gamesCount = {};

        try {
            for (const level of body.levels) {

                const queryRef = query(
                    collectionRef,
                    where('active', '==', true),
                    where('category', '==', body.selectedCurrency.toUpperCase()),
                    where('level', '==', level)
                );

                const snapshot = await getCountFromServer(queryRef);
                gamesCount[level] = snapshot.data().count;
            }

            return {
                selectedCurrency,
                gamesCount
            };
        } catch (error) {
            thunkApi.rejectWithValue(error);
        }
    }
);

export const fetchGameJoin = createAsyncThunk('fetchGameJoin',
    (body: JoinGameRequestBody, thunkApi) => axios
        .post<JoinGameResponseBody>(`${process.env.REACT_APP_BASE_RPSS_URL}/api/v1/game/join`, body)
        .then(response => response.data)
        .catch(thunkApi.rejectWithValue));

export const fetchGamePlay = createAsyncThunk('fetchGamePlay',
    (body: PlayHandRequestBody, thunkApi) => axios
        .put<PlayHandResponseBody>(`${process.env.REACT_APP_BASE_RPSS_URL}/api/v1/game/play`, body)
        .then(response => response.data)
        .catch(thunkApi.rejectWithValue));

export const fetchGameCancel = createAsyncThunk('fetchGameCancel',
    (_, thunkApi) => axios
        .delete<CancelGameResponseBody>(`${process.env.REACT_APP_BASE_RPSS_URL}/api/v1/game/cancel`)
        .then(response => response.data)
        .catch(thunkApi.rejectWithValue));

export const fetchGameTimeout = createAsyncThunk('fetchGametimeout',
    (_, thunkApi) => axios
        .delete<CancelGameResponseBody>(`${process.env.REACT_APP_BASE_RPSS_URL}/api/v1/game/timeout`)
        .then(response => response.data)
        .catch(thunkApi.rejectWithValue));

export const fetchRequestBot = createAsyncThunk('fetchRequestBot',
    (_, thunkApi) => axios
        .post<BotRequestResponse>(`${process.env.REACT_APP_BASE_RPSS_URL}/api/v1/bot/request`)
        .then(response => response.data)
        .catch(thunkApi.rejectWithValue));
