import { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';

import { NAMESPACES } from '../../../i18n';
import { useAppSelector } from '../../../redux/hooks';
import { getUserData } from '../../../redux/selectors/commonSelector';

interface ValidUserSessionProps {
    children: ReactElement,
    t: (key: string, options?: { ns: string }) => string;
}

export const ValidUserSession = ({ children, t }: ValidUserSessionProps): ReactElement => {
    const user = useAppSelector(getUserData);

    if (!user || !user.userId) {
        return (
            <Row className='m-1 mb-2'>
                <h6 className='text-center'>{t('authentication.valid-session.not-logged')}</h6>
            </Row>
        );
    }

    if (!user.activated) {
        return (
            <Row className='m-1 mb-2'>
                <h6 className='text-center'>{t('authentication.valid-session.not-activated')}</h6>
            </Row>
        );
    }

    return children;
};

export default withTranslation([NAMESPACES.RPS])(ValidUserSession);
