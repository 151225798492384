import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AVAILABLE_HANDS, HANDS_STATUS } from '../../../constants/hand';

interface DemoRpsState {
    inDemoGame: boolean;
    timer: number;
    isTimeout: boolean;
    isEndGame: boolean;
    currentPlayer: {
        loading: boolean;
        hand: string;
        status: HANDS_STATUS | null;
        previewHand: string;
    },
    shouldStartOpponentHand: boolean;
    opponent: {
        loading: boolean;
        hand: string;
        status: HANDS_STATUS | null;
    }
}

// Stryker disable next-line ObjectLiteral
const initialState: DemoRpsState = {
    inDemoGame: false,
    timer: 15,
    isTimeout: false,
    isEndGame: false,
    currentPlayer: {
        loading: false,
        hand: '',
        status: null,
        previewHand: ''
    },
    shouldStartOpponentHand: true,
    opponent: {
        loading: false,
        hand: '',
        status: null
    }
};

const updateGameStatus = (state: DemoRpsState) => {
    const playerHand = state.currentPlayer.hand;
    const opponentHand = state.opponent.hand;

    if (playerHand === opponentHand) {
        state.currentPlayer.status = HANDS_STATUS.draw;
        state.opponent.status = HANDS_STATUS.draw;
        state.isEndGame = true;
    } else if (
        (playerHand === AVAILABLE_HANDS.ROCK && opponentHand === AVAILABLE_HANDS.PAPER) ||
        (playerHand === AVAILABLE_HANDS.SCISSORS && opponentHand === AVAILABLE_HANDS.ROCK) ||
        (playerHand === AVAILABLE_HANDS.PAPER && opponentHand === AVAILABLE_HANDS.SCISSORS)
    ) {
        state.currentPlayer.status = HANDS_STATUS.looser;
        state.opponent.status = HANDS_STATUS.winner;
        state.isEndGame = true;
    } else {
        state.currentPlayer.status = HANDS_STATUS.winner;
        state.opponent.status = HANDS_STATUS.looser;
        state.isEndGame = true;
    }
};

export const demoRpsReducer = createSlice({
    name: 'demo-rps',
    initialState,
    reducers: {
        setInDemoGame: (state, action: PayloadAction<boolean>) => ({
            ...state,
            inDemoGame: action.payload
        }),
        decrementDemoTimer: state => {
            state.timer = !state.isTimeout && state.timer - 1;
        },
        setDemoTimeout: state => {
            state.isTimeout = true;
        },
        restartDemoGame: state => {
            state.timer = 15;
            state.isTimeout = false;
            state.isEndGame = false;
            state.currentPlayer.loading = false;
            state.currentPlayer.hand = '';
            state.currentPlayer.status = null;
            state.currentPlayer.previewHand = '';
            state.shouldStartOpponentHand = true;
            state.opponent.loading = false;
            state.opponent.hand = '';
            state.opponent.status = null;
        },
        clearDemoGame: state => {
            state.inDemoGame = false;
            state.timer = 15;
            state.isTimeout = false;
            state.isEndGame = false;
            state.currentPlayer.loading = false;
            state.currentPlayer.hand = '';
            state.currentPlayer.status = null;
            state.currentPlayer.previewHand = '';
            state.shouldStartOpponentHand = true;
            state.opponent.loading = false;
            state.opponent.hand = '';
            state.opponent.status = null;
        },
        setOnOverHand: (state, action: PayloadAction<string>) => {
            if (state.currentPlayer.status !== HANDS_STATUS.selected) {
                state.currentPlayer.previewHand = action.payload;
                state.currentPlayer.status = HANDS_STATUS.preview;
            }
        },
        setOnOutHand: state => {
            if (state.currentPlayer.status !== HANDS_STATUS.selected) {
                state.currentPlayer.hand = '';
                state.currentPlayer.status = null;
            }
        },
        setOnSelectedHand: (state, action: PayloadAction<string>) => {
            state.currentPlayer.hand = action.payload;
            state.currentPlayer.status = HANDS_STATUS.selected;

            if (state.opponent.status !== HANDS_STATUS.selected) {
                state.currentPlayer.loading = true;
            } else {
                state.currentPlayer.loading = false;
                state.opponent.loading = false;
                updateGameStatus(state);
            }
        },
        setOpponentHand: (state, action: PayloadAction<string>) => {
            state.opponent.hand = action.payload;
            state.opponent.status = HANDS_STATUS.selected;

            if (state.currentPlayer.status !== HANDS_STATUS.selected) {
                state.opponent.loading = true;
            } else {
                state.opponent.loading = false;
                state.currentPlayer.loading = false;
                updateGameStatus(state);
            }
        },
        setShouldStartOpponentHand: (state, action: PayloadAction<boolean>) => ({
            ...state,
            shouldStartOpponentHand: action.payload
        })
    }
});

export const {
    setInDemoGame,
    decrementDemoTimer,
    setDemoTimeout,
    restartDemoGame,
    clearDemoGame,
    setOnOverHand,
    setOnOutHand,
    setOnSelectedHand,
    setOpponentHand,
    setShouldStartOpponentHand
} = demoRpsReducer.actions;

export const demoRpsReducerInitialState = demoRpsReducer.getInitialState();

export default demoRpsReducer.reducer;
