import { useFetchCustomToken } from './useFetchCustomToken';
import { useSignInWithCustomToken } from './useSignInWithCustomToken';

import { useAppSelector } from '../../redux/hooks';
import { getUserData } from '../../redux/selectors/commonSelector';
import { useRequestsState } from '../requestStates/useRequestsState';

interface UseCustomAuthReturn {
    loading: boolean;
    error: boolean;
    success: boolean;
}

export const useCustomAuth = (): UseCustomAuthReturn => {
    const { getHasAnyRequestFailed, getIsAnyRequestPending, getHaveAllRequestsFulfilled } = useRequestsState();
    const userData = useAppSelector(getUserData);

    useFetchCustomToken(userData.userId);
    useSignInWithCustomToken();

    return {
        loading: getIsAnyRequestPending(['fetchCustomToken', 'signInWithFirebaseCustomToken']),
        error: getHasAnyRequestFailed(['fetchCustomToken', 'signInWithFirebaseCustomToken']),
        success: getHaveAllRequestsFulfilled(['fetchCustomToken', 'signInWithFirebaseCustomToken'])
    };
};