import { useCallback } from 'react';

import { useAppSelector } from '../../redux/hooks';
import { RequestStatesEnum } from '../../redux/reducers/requestStateReducer/requestStateReducer';
import { RootState } from '../../redux/store';

export interface UseRequestStateReturn {
    getIsAnyRequestPending: (requestActions: string[]) => boolean;
    getHasAnyRequestFailed: (requestActions: string[]) => boolean;
    getHaveAllRequestsFulfilled: (requestActions: string[]) => boolean;
}

export const useRequestsState = (): UseRequestStateReturn => {
    const requestStates = useAppSelector((state: RootState) => state.requestStates);

    const getIsAnyRequestPending = useCallback(requestActions =>
        requestActions.some(action =>
            requestStates[action]?.state === RequestStatesEnum.PENDING), [requestStates]);

    const getHasAnyRequestFailed = useCallback(requestActions =>
        requestActions.some(action =>
            requestStates[action]?.state === RequestStatesEnum.REJECTED), [requestStates]);
    
    const getHaveAllRequestsFulfilled = useCallback(requestActions =>
        requestActions.every(action =>
            requestStates[action]?.state === RequestStatesEnum.FULFILLED), [requestStates]);

    return {
        getIsAnyRequestPending,
        getHasAnyRequestFailed,
        getHaveAllRequestsFulfilled
    };
};