import { useEffect } from 'react';

import { useAppDispatch } from '../../redux/hooks';
import { fetchFirestoreActiveUserByCategories } from '../../redux/actions/rpsActions/rpsActions';

const frequency = 15000;

export const useActiveUsersByCategoriesScheduler = (selectedCurrency: string, levels: string[]) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!selectedCurrency || !levels.length) return;

        dispatch(fetchFirestoreActiveUserByCategories({ selectedCurrency, levels }));

        const interval = setInterval(() => {
            dispatch(fetchFirestoreActiveUserByCategories({ selectedCurrency, levels }));
        }, frequency);

        return () => {
            clearInterval(interval);
        };
    }, [selectedCurrency, levels, dispatch]);
};