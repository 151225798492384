import { ReactElement, useEffect } from 'react';

import { useAppDispatch } from '../../../redux/hooks';
import { setShowLoadingHands, setShowTitle } from '../../../redux/reducers/displayReducer/displayReducer';
import { useOpponentPlayHand } from '../hooks/useOpponentPlayHand';

interface DemoGameRenderingProps {
    children: ReactElement;
}

export const DemoGameRendering = ({ children }: DemoGameRenderingProps) => {
    const dispatch = useAppDispatch();
    useOpponentPlayHand();

    useEffect(() => {
        dispatch(setShowLoadingHands(false));
        dispatch(setShowTitle(false));

        return () => {
            dispatch(setShowLoadingHands(true));
            dispatch(setShowTitle(true));
        };
    }, [dispatch]);

    return children;
};

export default DemoGameRendering;