import { ReactElement } from 'react';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';

import { selectedCurrencySelector } from '../../../redux/selectors/commonSelector';
import { useAppSelector } from '../../../redux/hooks';
import { NAMESPACES } from '../../../i18n';

interface InGameVerifierProps {
    children: ReactElement,
    t: (key: string, options?: { ns: string }) => string;
}

export const CurrencyVerifier = ({ children, t }: InGameVerifierProps): ReactElement => {
    const selectedCurrency = useAppSelector(selectedCurrencySelector);

    if (!selectedCurrency) {
        return (
            <Row className='m-1 mb-2'>
                <h6 className='text-center'>{t('validations.selected-currency.not-selected')}</h6>
            </Row>
        );
    }

    return children;
};

export default withTranslation([NAMESPACES.RPS])(CurrencyVerifier);
