/* eslint-disable max-len */

/**
 * When change this object HANDS_STATUS
 * Pay attention to:
 * Hands.tsx proptypes of statusHands
 * selected-hand-animation.scss to animations names that matches with this properties
 */
export enum HANDS_STATUS {
    winner = 'winner',
    looser = 'looser',
    draw = 'draw',
    selected = 'selected',
    preview = 'preview'
}

export enum AVAILABLE_HANDS {
    ROCK = 'ROCK',
    PAPER = 'PAPER',
    SCISSORS = 'SCISSORS'
}

export const HANDS = {
    [AVAILABLE_HANDS.ROCK]: process.env.REACT_APP_ROCK_HAND,
    [AVAILABLE_HANDS.PAPER]: process.env.REACT_APP_PAPER_HAND,
    [AVAILABLE_HANDS.SCISSORS]: process.env.REACT_APP_SCISSORS_HAND
} as const;

export const EMPTY_HAND = process.env.REACT_APP_EMPTY_HAND;

// Max and Min time to generate new hand on animations like lobby
export const MAX_TIME_LOADING = 7000;
export const MIN_TIME_LOADING = 2500;

// Time to change hand when in a match and waiting for opponent
export const TIME_LOADING_SELECTED_HAND = 1500;