
export interface UseSendParentData {
    sendMessageToParent: (type: string, data?: any) => void;
}

export const useSendParentData = (): UseSendParentData => {
    const sendMessageToParent = (type: string, data?: any) => {
        if (window.parent && window !== window.parent) {
            window.parent.postMessage({ type, data }, '*');
        }
    };

    return { sendMessageToParent };
};
