import GameHeader from '../../game/game-rendering/game-header/GameHeader';
import { useAppSelector } from '../../../redux/hooks';
import { getUserData } from '../../../redux/selectors/commonSelector';
import { getDemoTime } from '../../../redux/selectors/demoRpsSelector';

export const DemoGameHeaderController = () => {
    const user = useAppSelector(getUserData);
    const timer = useAppSelector(getDemoTime);

    return (
        <GameHeader
            player1Name={user.username}
            player2Name="CryptoSkill"
            renderingGameTime={timer}
        />
    );
};

export default DemoGameHeaderController;