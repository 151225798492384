/* eslint-disable @typescript-eslint/no-unused-vars */
import { BackendModule } from 'i18next';

export const LazyImportPlugin: BackendModule = {
    type: 'backend',
    init: (_services, _backendOptions, _i18nextOptions) => { },
    read: (language, namespace, callback) => {
        import(`../langs/${language}/${namespace}.json`)
            .then(resources => { callback(null, resources.default); })
            .catch(error => callback(error, false));
    }
};

export default LazyImportPlugin;
