import { useCallback, useEffect } from 'react';
import { CompiledMessage } from '@cryptoskill/rps-contract';

import { RequestStatesEnum, requestStateViewed } from '../../redux/reducers/requestStateReducer/requestStateReducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';


export interface UseRequestStateSubscriptionProps {
    onFulfilled?: (actionType: string, message: CompiledMessage) => void;
    onPending?: (actionType: string) => void;
    onRejected?: (actionType: string, message: CompiledMessage) => void;
}

export const useRequestStateSubscription = ({ onFulfilled, onPending, onRejected }: UseRequestStateSubscriptionProps) => {
    const requestStates = useAppSelector(state => state.requestStates);
    const dispatch = useAppDispatch();

    const handleStateChange = useCallback(() => {
        if (requestStates)
            Object.keys(requestStates).forEach(actionType => {
                const { state, viewed, message } = requestStates[actionType];

                if (!viewed) {
                    if (state === RequestStatesEnum.FULFILLED && onFulfilled) {
                        onFulfilled(actionType, message);
                        dispatch(requestStateViewed(actionType));
                    } else if (state === RequestStatesEnum.PENDING && onPending) {
                        onPending(actionType);
                        dispatch(requestStateViewed(actionType));
                    } else if (state === RequestStatesEnum.REJECTED && onRejected) {
                        onRejected(actionType, message);
                        dispatch(requestStateViewed(actionType));
                    }
                }
            });
    }, [dispatch, onFulfilled, onRejected, onPending, requestStates]);

    useEffect(() => {
        handleStateChange();
    }, [handleStateChange, requestStates]);
};
