import { ReactElement, useCallback } from 'react';

import { useAppDispatch } from '../../../../redux/hooks';
import { useRequestsState } from '../../../../hooks/requestStates/useRequestsState';
import { fetchGameJoin } from '../../../../redux/actions/rpsActions/rpsActions';
import { TableDataType } from '../../../../hooks/rps/utils/useTableData';
import LobbyTableBodyRow from '../lobby-table-body-row/LobbyTableBodyRow';

export interface LobbyTableBodyProps {
    categoryName: string;
    tableData: TableDataType[],
    headerKeys: string[],
    selectedCoin: string
}

const LobbyTableBody = ({ categoryName, tableData, headerKeys, selectedCoin }: LobbyTableBodyProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { getIsAnyRequestPending } = useRequestsState();

    const handleJoinGame = useCallback(amount => () => {
        dispatch(fetchGameJoin({
            categoryName,
            categoryLevel: amount
        }));
    }, [dispatch, categoryName]);

    return (
        <tbody>
            {tableData.map(tableRowData => (
                <LobbyTableBodyRow
                    key={tableRowData.id}
                    tableRowData={tableRowData}
                    headerKeys={headerKeys}
                    selectedCoin={selectedCoin}
                    handleJoinGame={handleJoinGame}
                    shouldDisableButtons={getIsAnyRequestPending(['fetchGameJoin'])}
                />
            ))}
        </tbody>
    );
};

export default LobbyTableBody;