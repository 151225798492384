import { ReactElement, useEffect } from 'react';

import { useAppSelector } from '../../../../redux/hooks';
import { getUserData } from '../../../../redux/selectors/commonSelector';
import { getGameStateSelector, getRenderingPreviewHand } from '../../../../redux/selectors/rpsSelector';
import { checkHandStatus } from '../../../../utils/utils';
import { HANDS_STATUS } from '../../../../constants/hand';
import { POST_MESSAGE_TYPES } from '../../../../constants/postMessagesTypes';
import GameBody from '../game-body/GameBody';

export const GameBodyController = (): ReactElement => {
    const user = useAppSelector(getUserData);
    const publicGame = useAppSelector(getGameStateSelector);
    const previewHand = useAppSelector(getRenderingPreviewHand);

    const { myHand, myHandHash, opponentHand, opponentHandHash } =
        publicGame.playerOneId === user.userId ? {
            myHand: publicGame.playerOneHand,
            myHandHash: publicGame.playerOneHandHash,
            opponentHand: publicGame.playerTwoHand,
            opponentHandHash: publicGame.playerTwoHandHash
        } : {
            myHand: publicGame.playerTwoHand,
            myHandHash: publicGame.playerTwoHandHash,
            opponentHand: publicGame.playerOneHand,
            opponentHandHash: publicGame.playerOneHandHash
        };

    // Determine properties for player1's hand
    const player1Status = checkHandStatus(myHand, myHandHash, opponentHand);
    const isPlayer1PreviewHand = previewHand && !myHandHash;
    const shouldLoadPlayer1 = player1Status === HANDS_STATUS.selected || !!myHand && !opponentHand;
    const statusPlayer1 = isPlayer1PreviewHand ? HANDS_STATUS.preview : player1Status ?? HANDS_STATUS.selected;
    const showHandPlayer1 = statusPlayer1 === HANDS_STATUS.preview ? previewHand : statusPlayer1 === HANDS_STATUS.selected ? '' : myHand;

    // Determine properties for player2's hand
    const player2Status = checkHandStatus(opponentHand, opponentHandHash, myHand);
    const shouldLoadPlayer2 = player2Status === HANDS_STATUS.selected || !!opponentHand && !myHand;
    const statusPlayer2 = player2Status ?? HANDS_STATUS.selected;
    const showHandPlayer2 = shouldLoadPlayer2 ? '' : opponentHand;

    useEffect(() => {
        if (player1Status === HANDS_STATUS.winner) {
            window.parent.postMessage({ type: POST_MESSAGE_TYPES.DISPATCH_CONFETTIS }, '*');
        }
    }, [player1Status]);

    return (
        <GameBody
            shouldLoadPlayer1={shouldLoadPlayer1}
            statusPlayer1={statusPlayer1}
            showHandPlayer1={showHandPlayer1}
            shouldLoadPlayer2={shouldLoadPlayer2}
            statusPlayer2={statusPlayer2}
            showHandPlayer2={showHandPlayer2}
        />
    );
};

export default GameBodyController;