import { ReactElement, useCallback, useEffect } from 'react';
import { GameState, QueuePositionState } from '@cryptoskill/rps-contract';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    getGameStateSelector,
    getQueuePositionSelector
} from '../../../../redux/selectors/rpsSelector';
import { getUserData } from '../../../../redux/selectors/commonSelector';
import { setShowLoadingHands, setShowTitle } from '../../../../redux/reducers/displayReducer/displayReducer';
import { NAMESPACES } from '../../../../i18n';
import { AVAILABLE_HANDS, HANDS_STATUS } from '../../../../constants/hand';
import Hand, { SIZES } from '../../../core/hand/Hand';
import { fetchGameJoin, fetchGamePlay } from '../../../../redux/actions/rpsActions/rpsActions';
import { clearGameState, clearQueuePositionData, setPreviewHand } from '../../../../redux/reducers/rpsReducer/rpsReducer';
import { checkHandStatus } from '../../../../utils/utils';
import { useRequestsState } from '../../../../hooks/requestStates/useRequestsState';
import { useSendParentData } from '../../../../hooks/iframe/core/useSendParentData';
import { POST_MESSAGE_TYPES } from '../../../../constants/postMessagesTypes';

export interface GameFooterControllerProps {
    t: (key: string) => string;
}

export const GameFooterController = ({ t }: GameFooterControllerProps): ReactElement => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUserData);
    const game = useAppSelector(getGameStateSelector);
    const queuePosition = useAppSelector(getQueuePositionSelector);
    const { getIsAnyRequestPending } = useRequestsState();
    const { sendMessageToParent } = useSendParentData();

    const getResult = {
        [HANDS_STATUS.winner]: t('game.game-footer-controller.game-status.winner'),
        [HANDS_STATUS.looser]: t('game.game-footer-controller.game-status.loser'),
        [HANDS_STATUS.draw]: t('game.game-footer-controller.game-status.draw')
    };

    useEffect(() => {
        dispatch(setShowLoadingHands(false));
        dispatch(setShowTitle(false));

        return () => {
            dispatch(setShowLoadingHands(true));
            dispatch(setShowTitle(true));
        };
    }, [dispatch]);

    const handleSelectedHand = useCallback((hand: string) => () => {
        dispatch(fetchGamePlay({ hand }));
    }, [dispatch]);

    const handleOnHoverHand = useCallback((hand: string) => () => {
        dispatch(setPreviewHand(hand));
    }, [dispatch]);

    const handleOnOutHand = useCallback(() => {
        dispatch(setPreviewHand(''));
    }, [dispatch]);

    if ([GameState.ONE_PLAYER_PLAYED].includes(game.state) || getIsAnyRequestPending(['fetchGamePlay'])) {
        const hasPlayerPlayed = (game.playerOneId === user.userId && game.playerOneHandHash)
            || (game.playerTwoId === user.userId && game.playerTwoHandHash);

        if (hasPlayerPlayed || getIsAnyRequestPending(['fetchGamePlay'])) {
            // Footer with message waiting for opponent to play
            return (
                <Row className='m-1 mb-2'>
                    <h6 className='text-center'>{t('game.game-footer-controller.waiting-for-opponent')}</h6>
                </Row>
            );
        }
    }

    if ([GameState.BOTH_PLAYERS_PLAYED].includes(game.state)) {
        return (
            <Row className='m-1 mb-2'>
                <h6 className='text-center'>{t('game.game-footer-controller.process-end-game')}</h6>
            </Row>
        );
    }

    if ([GameState.DRAW, GameState.PLAYER_ONE_WON, GameState.PLAYER_TWO_WON].includes(game.state)) {
        const { myHand, myHandHash, opponentHand } =
            game.playerOneId === user.userId ? {
                myHand: game.playerOneHand,
                myHandHash: game.playerOneHandHash,
                opponentHand: game.playerTwoHand
            } : {
                myHand: game.playerTwoHand,
                myHandHash: game.playerTwoHandHash,
                opponentHand: game.playerOneHand
            };

        if (queuePosition.state === QueuePositionState.UNRESERVED) {
            sendMessageToParent(POST_MESSAGE_TYPES.REFRESH_BALANCES);
            // Footer with actions to return lobby or rematch
            return (
                <Row className="m-4">
                    <Row className='text-center'>
                        <h3>{getResult[checkHandStatus(myHand, myHandHash, opponentHand)]}</h3>
                    </Row>
                    <Row>
                        <Col className='m-1'>
                            <Button
                                className="shadow-none w-100"
                                variant="outline-info"
                                onClick={() => {
                                    dispatch(clearQueuePositionData());
                                    dispatch(clearGameState());
                                }}
                            >
                                {t('game.game-footer-controller.return-lobby')}
                            </Button>
                        </Col>
                        <Col className='m-1'>
                            <Button
                                className="shadow-none w-100"
                                variant="info"
                                disabled={getIsAnyRequestPending(['fetchGameJoin'])}
                                onClick={() => dispatch(fetchGameJoin({ categoryName: game.category, categoryLevel: game.level }))}
                            >
                                {t('game.game-footer-controller.rematch')}
                            </Button>
                        </Col>
                    </Row>
                </Row>
            );
        } else if (queuePosition.state === QueuePositionState.IN_GAME) {
            // Footer with message "processing results"
            return (
                <Row className='m-4 text-center'>
                    <h3>{getResult[checkHandStatus(myHand, myHandHash, opponentHand)]}</h3>
                    <h6 className='text-center'>{t('game.game-footer-controller.process-end-game')}</h6>
                </Row>
            );
        } else if (queuePosition.state === QueuePositionState.GAME_FINISHED) {
            return (
                <Row className="m-4 text-center">
                    <h3>{getResult[checkHandStatus(myHand, myHandHash, opponentHand)]}</h3>
                    <h6>{t('game.game-footer-controller.process-unreserve')}</h6>
                </Row>
            );
        }
    }

    // Footer with actions
    return (
        <Row className='mt-4 mb-4 flex-nowrap'>
            {
                Object.keys(AVAILABLE_HANDS).map(hand => (
                    <Col key={hand}>
                        <Hand
                            size={SIZES.sm}
                            showHand={hand}
                            handleOnClick={handleSelectedHand(hand)}
                            handleOnHover={handleOnHoverHand(hand)}
                            handleOnOut={handleOnOutHand}
                        />
                    </Col>
                ))
            }
        </Row>
    );
};

export default withTranslation([NAMESPACES.RPS])(GameFooterController);