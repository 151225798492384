import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getCustomTokenSelector } from '../../redux/selectors/rpsSelector';
import { signInWithFirebaseCustomToken } from '../../redux/actions/authActions/authActions';

export const useSignInWithCustomToken = () => {
    const dispatch = useAppDispatch();
    const customToken = useAppSelector(getCustomTokenSelector);

    useEffect(() => {
        if (customToken) {
            dispatch(signInWithFirebaseCustomToken(customToken));
        }
    }, [customToken, dispatch]);
};
