
import { ReactElement, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';

import { NAMESPACES } from '../../../i18n';
import { useAppSelector } from '../../../redux/hooks';
import { getQueuePositionSelector, getGameSubscriptionState, getGameStateSelector } from '../../../redux/selectors/rpsSelector';
import { useGameSubscription } from '../../../hooks/rps/useGameSubscription';
import { SubscriptionsStates } from '../../../redux/reducers/rpsReducer/rpsReducer';

interface GameSubscriptionProps {
    children: ReactElement,
    t: (key: string, options?: { ns: string }) => string;
}

export const GameSubscription = ({ children, t }: GameSubscriptionProps): ReactElement => {
    const queuePosition = useAppSelector(getQueuePositionSelector);
    const publicGame = useAppSelector(getGameStateSelector);
    const gameSubscriptionState = useAppSelector(getGameSubscriptionState);

    useGameSubscription(queuePosition.gameId);

    const memoizedChildren = useMemo(() => children, [children]);

    if (gameSubscriptionState === SubscriptionsStates.LOADING) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.game-subscription.loading')}</h6>
            </Row>
        );
    }

    if (gameSubscriptionState === SubscriptionsStates.ERROR) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.game-subscription.error')}</h6>
            </Row>
        );
    }

    if (gameSubscriptionState === SubscriptionsStates.SUCCESS && !publicGame) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.game-subscription.empty')}</h6>
            </Row>
        );
    }

    return gameSubscriptionState === SubscriptionsStates.SUCCESS && memoizedChildren;
};

export default withTranslation([NAMESPACES.RPS])(GameSubscription);
