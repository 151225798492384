/* eslint-disable @typescript-eslint/no-unused-vars */
import { POST_MESSAGE_TYPES } from '../../constants/postMessagesTypes';
import { useRequestStateSubscription } from '../requestStates/useRequestStateSubscription';

const blackList = [];

export const useRpsNotifications = () => {
    useRequestStateSubscription({
        onFulfilled: (actionType, message) => {
            if (blackList.includes(actionType)) return;

            // window.parent.postMessage({
            //     type: POST_MESSAGE_TYPES.NOTIFICATION,
            //     actionType,
            //     toasterType: 'success',
            //     message,
            //     isLoading: false
            // }, '*');
        },
        onPending: actionType => {
            if (blackList.includes(actionType)) return;

            //     window.parent.postMessage({
            //         type: POST_MESSAGE_TYPES.NOTIFICATION,
            //         actionType,
            //         message: `${actionType} Pending`,
            //         isLoading: true
            //     }, '*');
        },
        onRejected: (actionType, message) => {
            if (blackList.includes(actionType)) return;

            // window.parent.postMessage({
            //     type: POST_MESSAGE_TYPES.NOTIFICATION,
            //     actionType,
            //     toasterType: 'error',
            //     message: message?.key || 'Internal Server Error',
            //     isLoading: false
            // }, '*');
        }
    });
};

export default useRpsNotifications;
