import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useCallback } from 'react';
import { withTranslation } from 'react-i18next';

import { AVAILABLE_HANDS, HANDS_STATUS } from '../../../constants/hand';
import Hand, { SIZES } from '../../core/hand/Hand';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
    clearDemoGame,
    restartDemoGame,
    setOnOutHand,
    setOnOverHand,
    setOnSelectedHand
} from '../../../redux/reducers/demoRpsReducer/demoRpsReducer';
import { getCurrentPlayerHand, getCurrentPlayerStatus } from '../../../redux/selectors/demoRpsSelector';
import { NAMESPACES } from '../../../i18n';

const getStatusMessage = {
    [HANDS_STATUS.winner]: 'You Win!',
    [HANDS_STATUS.looser]: 'You Lose!',
    [HANDS_STATUS.draw]: 'Draw!'
};

export interface DemoGameFooterControllerProps {
    t: (key: string) => string;
}

export const DemoGameFooterController = ({ t }: DemoGameFooterControllerProps) => {
    const dispatch = useAppDispatch();
    const playerStatus = useAppSelector(getCurrentPlayerStatus);
    const playerHand = useAppSelector(getCurrentPlayerHand);

    const handleSelectedHand = useCallback((hand: string) => () => {
        dispatch(setOnSelectedHand(hand));
    }, [dispatch]);

    const handleOnHoverHand = useCallback((hand: string) => () => {
        dispatch(setOnOverHand(hand));
    }, [dispatch]);

    const handleOnOutHand = useCallback(() => {
        dispatch(setOnOutHand());
    }, [dispatch]);

    if ([HANDS_STATUS.winner, HANDS_STATUS.looser, HANDS_STATUS.draw].includes(playerStatus)) {
        return (
            <Row className="m-2">
                <Row className='text-center'>
                    <h6 className='text-center'>{getStatusMessage[playerStatus]}</h6>
                </Row>
                <Col className='m-1'>
                    <Button
                        className='shadow-none w-100'
                        variant='info'
                        onClick={() => {
                            dispatch(clearDemoGame());
                        }}
                    >
                        {t('game.game-footer-controller.return-lobby')}
                    </Button>
                </Col>
                <Col className='m-1'>
                    <Button
                        className='shadow-none w-100'
                        variant='info'
                        onClick={() => dispatch(restartDemoGame())}
                    >
                        {t('game.game-footer-controller.rematch')}
                    </Button>
                </Col>
            </Row>
        );
    }

    if (playerHand) {
        return (
            <Row className='mt-4 mb-4 flex-nowrap'>
                <h6 className='text-center'>{t('game.game-footer-controller.waiting-for-opponent')}</h6>
            </Row>
        );
    }

    return (
        <Row className='mt-4 mb-4 flex-nowrap'>
            {
                Object.keys(AVAILABLE_HANDS).map(hand => (
                    <Col key={hand}>
                        <Hand
                            size={SIZES.sm}
                            showHand={hand}
                            handleOnClick={handleSelectedHand(hand)}
                            handleOnHover={handleOnHoverHand(hand)}
                            handleOnOut={handleOnOutHand}
                        />
                    </Col>
                ))
            }
        </Row>
    );
};

export default withTranslation([NAMESPACES.RPS])(DemoGameFooterController);