import { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

import { NAMESPACES } from '../../../../i18n';
import { TableDataType } from '../../../../hooks/rps/utils/useTableData';
import TableBodyRowElement from '../lobby-table-body-row-element/LobbyTableBodyRowElement';

export interface LobbyTableBodyRowProps {
    tableRowData: TableDataType;
    headerKeys: string[];
    selectedCoin: string;
    handleJoinGame: (amount: any) => () => void;
    shouldDisableButtons: boolean;
    t: (key: string) => string;
}

const LobbyTableBodyRow = ({
    tableRowData,
    headerKeys,
    selectedCoin,
    handleJoinGame,
    shouldDisableButtons,
    t
}: LobbyTableBodyRowProps): ReactElement => {
    return (
        <tr className="align-middle">
            {headerKeys.map(headerKey => (
                <TableBodyRowElement
                    key={headerKey}
                    headerKey={headerKey}
                    selectedCoin={selectedCoin}
                    tableRowData={tableRowData}
                />
            ))}
            <td className='p-1'>
                <Button
                    className="shadow-none w-100"
                    variant="outline-info"
                    onClick={handleJoinGame(tableRowData.amount)}
                    disabled={shouldDisableButtons}
                >
                    {t('lobby.table.buttons.join')}
                </Button>
            </td>
        </tr>
    );
};

export default withTranslation([NAMESPACES.RPS])(LobbyTableBodyRow);