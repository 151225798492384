import { RootState } from '../store';

export const getInDemoGame = (state: RootState) => state.demoRps.inDemoGame;

export const getDemoTime = (state: RootState) => state.demoRps.timer;

export const getDemoIsTimeout = (state: RootState) => state.demoRps.isTimeout;
export const getDemoIsEndGame = (state: RootState) => state.demoRps.isEndGame;

export const getCurrentPlayerLoading = (state: RootState) => state.demoRps.currentPlayer.loading;
export const getCurrentPlayerHand = (state: RootState) => state.demoRps.currentPlayer.hand;
export const getCurrentPlayerStatus = (state: RootState) => state.demoRps.currentPlayer.status;
export const getCurrentPlayerPreviewHand = (state: RootState) => state.demoRps.currentPlayer.previewHand;

export const getShouldStartOpponentHand = (state: RootState) => state.demoRps.shouldStartOpponentHand;
export const getOpponentHand = (state: RootState) => state.demoRps.opponent.hand;
export const getOpponentStatus = (state: RootState) => state.demoRps.opponent.status;
export const getOpponentLoading = (state: RootState) => state.demoRps.opponent.loading;