import { Dispatch, SetStateAction } from 'react';

import { AVAILABLE_HANDS, HANDS_STATUS } from '../constants/hand';

export const setIntervalGenerateRandomHands = (
    handsMaxLength: number, 
    randomHand: string, 
    hands: string[],
    setRandomHand: Dispatch<SetStateAction<string>>, 
    maxTimeAnimation: number, 
    minTimeAnimation: number): ReturnType<typeof setInterval> =>

    setInterval(() => {
        let randomNumber = Math.floor(Math.random() * handsMaxLength);

        if (randomHand === hands[randomNumber]) {
            randomNumber = (randomNumber + 1) % (handsMaxLength - 1);
        }

        setRandomHand(hands[randomNumber]);
    }, (Math.floor(Math.random() * (maxTimeAnimation - minTimeAnimation)) + minTimeAnimation));

export const checkHandStatus = (hand: string, handHash: string, opponentHand: string): string => {
    if (handHash && !hand) {
        return HANDS_STATUS.selected;
    }

    if (!hand || !opponentHand) {
        return '';
    }

    if (hand === opponentHand) {
        return HANDS_STATUS.draw;
    }

    if ((hand === AVAILABLE_HANDS.ROCK && opponentHand === AVAILABLE_HANDS.PAPER)
    || (hand === AVAILABLE_HANDS.SCISSORS && opponentHand === AVAILABLE_HANDS.ROCK)
    || (hand === AVAILABLE_HANDS.PAPER && opponentHand === AVAILABLE_HANDS.SCISSORS)) {
        return HANDS_STATUS.looser;
    }

    return HANDS_STATUS.winner;
};