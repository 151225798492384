import { getApps, initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

const isLocal = false; //process.env.NODE_ENV === 'development';
//const isDev = window.location.hostname.includes('dev');

let rpssApp;

if (isLocal) {
    rpssApp = initializeApp({
        apiKey: 'demo-rps-ket',
        projectId: 'demo-rps-projectid'
    });
} else {
    rpssApp = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID
    }, 'rpss');
}

/**
 else if (isDev) {
    rpssApp = initializeApp({
        apiKey: 'AIzaSyC_Dk-SaZQGxroEqoovpk9-USM84756628',
        authDomain: 'rpss-dev.firebaseapp.com',
        projectId: 'rpss-dev',
        storageBucket: 'rpss-dev.appspot.com',
        messagingSenderId: '841862072768',
        appId: '1:841862072768:web:b40fe8ebced965c0c5e025'
    }, 'rpss');
}
 */

export let rpssAuth;
export let rpssFirestore;

if (getApps().length >= 0) {
    rpssAuth = getAuth(rpssApp);
    rpssFirestore = getFirestore(rpssApp);

    if (isLocal) {
        connectAuthEmulator(rpssAuth, 'http://localhost:9099');
        connectFirestoreEmulator(rpssFirestore, 'localhost', 8080);
    }
}
