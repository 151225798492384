/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LazyImportPlugin from './plugins/LazyImportPlugin';
import { LANGS, NAMESPACES } from './constants';

i18n
    .use(LazyImportPlugin)
    .use(initReactI18next)
    .init({
        fallbackLng: LANGS.EN,
        ns: [
            NAMESPACES.RPS
        ],
        defaultNS: NAMESPACES.RPS,
        interpolation: {
            escapeValue: false
        }
    });

export * from './constants';
export default i18n;
