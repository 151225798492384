import { Col, Image, Row } from 'react-bootstrap';

import { TableDataType } from '../../../../hooks/rps/utils/useTableData';

export interface TableBodyRowElementProps {
    headerKey: string;
    selectedCoin: string;
    tableRowData: TableDataType;
}

const TableBodyRowElement = ({ headerKey, selectedCoin, tableRowData }: TableBodyRowElementProps) => {
    const renderAmountCellContent = () => (
        <Row xs="auto" className='flex-nowrap'>
            <Col className='p-1'>
                <Image
                    width={15}
                    rounded
                    alt={selectedCoin}
                    src={`https://cdn.rawgit.com/cjdowner/cryptocurrency-icons/master/svg/color/${selectedCoin}.svg`} />
            </Col>
            <Col className='p-1'>
                {tableRowData[headerKey]}
            </Col>
        </Row>
    );

    return (
        <td>
            {headerKey === 'amount' ? renderAmountCellContent() : tableRowData[headerKey]}
        </td>
    );
};

export default TableBodyRowElement;