import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { AVAILABLE_HANDS } from '../../../constants/hand';
import { setOpponentHand, setShouldStartOpponentHand } from '../../../redux/reducers/demoRpsReducer/demoRpsReducer';
import { getShouldStartOpponentHand } from '../../../redux/selectors/demoRpsSelector';

export const useOpponentPlayHand = () => {
    const dispatch = useAppDispatch();
    const shouldStartOpponnentHand = useAppSelector(getShouldStartOpponentHand);

    useEffect(() => {
        if (shouldStartOpponnentHand) {
            const randomTimeToPlay = Math.floor(Math.random() * 4500);

            setTimeout(() => {
                const availableHandsKeys = Object.keys(AVAILABLE_HANDS);
                const randomNumber = Math.floor(Math.random() * availableHandsKeys.length);

                dispatch(setOpponentHand(AVAILABLE_HANDS[availableHandsKeys[randomNumber]]));
            }, randomTimeToPlay);

            dispatch(setShouldStartOpponentHand(false));
        }
    }, [dispatch, shouldStartOpponnentHand]);
};