import { useEffect } from 'react';

import GameFooterController from './game-footer-controller/GameFooterController';
import GameTimer from './game-timer/GameTimer';
import GameHeaderController from './game-header-controller/GameHeaderController';
import GameBodyController from './game-body-controller/GameBodyController';

import { useAppDispatch } from '../../../redux/hooks';
import { setShowLoadingHands, setShowTitle } from '../../../redux/reducers/displayReducer/displayReducer';

export const GameRendering = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setShowLoadingHands(false));
        dispatch(setShowTitle(false));

        return () => {
            dispatch(setShowLoadingHands(true));
            dispatch(setShowTitle(true));
        };
    }, [dispatch]);

    return (
        <GameTimer>
            <>
                <GameHeaderController />
                <GameBodyController />
                <GameFooterController />
            </>
        </GameTimer>
    );
};

export default GameRendering;