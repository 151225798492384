import { useEffect, useRef } from 'react';

import { POST_MESSAGE_TYPES } from '../../constants/postMessagesTypes';

export const useSendParentHeight = () => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            const { height: currentHeight } = entries[0].contentRect;
            window.parent.postMessage({ type: POST_MESSAGE_TYPES.IFRAME_HEIGHT, height: currentHeight }, '*');
        });

        const currentNode = ref.current;

        if (currentNode) {
            observer.observe(currentNode);
        }

        return () => {
            if (currentNode) {
                observer.unobserve(currentNode);
            }
        };
    }, []);

    return ref;
};