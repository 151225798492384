import { ReactElement } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';

import { NAMESPACES } from '../../../../i18n';

export interface GameHeaderProps {
    player1Name: string;
    player2Name: string;
    renderingGameTime: number;
    t: (key: string) => string;
}

export const GameHeader = ({
    player1Name,
    player2Name,
    renderingGameTime,
    t
}: GameHeaderProps): ReactElement => {
    return (
        <Row className='mt-4 mb-4 flex-nowrap text-center'>
            <Col><h5 className='fw-bolder'>{player1Name}</h5></Col>
            <Col lg="1" className="w-auto">
                <Row className='justify-content-center fw-bolder'>{t('timer')}</Row>
                <Row className='justify-content-center'>{renderingGameTime}</Row>
            </Col>
            <Col><h5 className='fw-bolder'>{player2Name}</h5></Col>
        </Row>
    );
};

export default withTranslation([NAMESPACES.RPS])(GameHeader);