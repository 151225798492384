import { ReactElement } from 'react';

import InGameVerifier from './in-game-verifier/InGameVerifier';
import GameRouter from './game-router/GameRouter';

export const RpsGameRouter = (): ReactElement => {
    return (
        <InGameVerifier>
            <GameRouter />
        </InGameVerifier>
    );
};

export default RpsGameRouter;
