import { ReactElement, useMemo } from 'react';
import { Provider } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';

import { store } from '../../redux/store';
import { AVAILABLE_HANDS, HANDS } from '../../constants/hand';
import i18n from '../../i18n';

interface ProvidersProps {
    children: React.ReactNode;
}

export const Providers = ({ children }: ProvidersProps): ReactElement => (
    <I18nextProvider i18n={i18n}>
        <HelmetProvider>
            <Helmet>
                <link rel="dns-prefetch" href={HANDS[AVAILABLE_HANDS.ROCK]} />
                <link rel="dns-prefetch" href={HANDS[AVAILABLE_HANDS.PAPER]} />
                <link rel="dns-prefetch" href={HANDS[AVAILABLE_HANDS.SCISSORS]} />
                <link rel="dns-prefetch" href={process.env.REACT_APP_BASE_AUTH_URL} />
                <link rel="dns-prefetch" href={process.env.REACT_APP_BASE_RPSS_URL} />
            </Helmet>
            <Provider store={store}>
                {useMemo(() => (
                    children
                ), [children])}
            </Provider>
        </HelmetProvider>
    </I18nextProvider>
);

export default Providers;
