import { PublicGameData, QueuePositionData } from '@cryptoskill/rps-contract';
import { DocumentSnapshot, doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';

import { rpssFirestore } from '../../firebase/firebase';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { SubscriptionsStates, setGameState, setGameStateSubscriptionState } from '../../redux/reducers/rpsReducer/rpsReducer';
import { getGameSubscriptionState } from '../../redux/selectors/rpsSelector';

export const useGameSubscription = (gameId: QueuePositionData['gameId']) => {
    const dispatch = useAppDispatch();
    const gameSubscriptionState = useAppSelector(getGameSubscriptionState);

    useEffect(() => {
        if (gameSubscriptionState === SubscriptionsStates.SUCCESS) return;

        dispatch(setGameStateSubscriptionState(SubscriptionsStates.LOADING));

        const docRef = doc(rpssFirestore, 'games', gameId);

        const unsubscribe = onSnapshot(docRef,
            (documentSnapshot: DocumentSnapshot<PublicGameData>) => {
                if (documentSnapshot.exists()) {
                    if (documentSnapshot.data()) {
                        dispatch(setGameState(documentSnapshot.data()));
                    }
                }

                dispatch(setGameStateSubscriptionState(SubscriptionsStates.SUCCESS));
            },
            () => {
                dispatch(setGameStateSubscriptionState(SubscriptionsStates.ERROR));
            });

        return () => {
            dispatch(setGameStateSubscriptionState(SubscriptionsStates.NONE));
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameId, dispatch]);
};