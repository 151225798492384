import { useCallback } from 'react';

import { useParentEventSubscription } from './core/useParentEventSubscription';

import { useAppDispatch } from '../../redux/hooks';
import { setMetadata } from '../../redux/reducers/commonReducer/commonReducer';

export const useParentDataListener = () => {
    const dispatch = useAppDispatch();

    const handleMetadataEvent = useCallback((event: MessageEvent) => {
        console.log('useParentDataListener: ', event);
        if (event.data.type === 'CRYPTOSKILL_METADATA') {
            dispatch(setMetadata(event.data));
        }
    }, [dispatch]);

    useParentEventSubscription(handleMetadataEvent);
};