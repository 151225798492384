
import { ReactElement } from 'react';

import { useAppSelector } from '../../../redux/hooks';
import { getIsPlayerInGame } from '../../../redux/selectors/rpsSelector';
import DemoGame from '../../demo-game/DemoGame';
import Game from '../../game/Game';
import Lobby from '../../lobby/Lobby';
import { getInDemoGame } from '../../../redux/selectors/demoRpsSelector';

export const GameRouter = (): ReactElement => {
    const inDemo = useAppSelector(getInDemoGame);
    const inPlay = useAppSelector(getIsPlayerInGame);

    if (inDemo) {
        return <DemoGame />;
    }

    if (inPlay) {
        return <Game />;
    }

    return <Lobby />;
};

export default GameRouter;
