import { ReactElement, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';

import Hand from '../../core/hand/Hand';
import { NAMESPACES } from '../../../i18n';
import { useAppSelector } from '../../../redux/hooks';
import { getShowLoadingHands, getShowTitle } from '../../../redux/selectors/displaySelector';

import './global-loading-hands.scss';

interface GlobalLoadingHandsProps {
    children: ReactElement,
    t: (key: string, options?: { ns: string }) => string;
}

export const GlobalLoadingHands = ({ children, t }: GlobalLoadingHandsProps): ReactElement => {
    console.log('GlobalLoadingHands');
    const shouldShowLoadingHands = useAppSelector(getShowLoadingHands);
    const shouldShowTitle = useAppSelector(getShowTitle);

    const memoHand = useMemo(() => (
        <Hand loading />
    ), []);

    const shouldRenderTitle = shouldShowTitle && (
        <Row className='m-1 mb-2'>
            <h1 className='text-center title'>{t('title')}</h1>
        </Row>
    );

    const shouldRenderLoadingHands = shouldShowLoadingHands && (
        <Row>
            <Col className='with-loading-hands'>{memoHand}</Col>
            <Col className='with-loading-hands'>{memoHand}</Col>
            <Col className='with-loading-hands'>{memoHand}</Col>
        </Row>
    );

    return (
        <>
            {shouldRenderLoadingHands}
            {shouldRenderTitle}
            {children}
        </>
    );
};

export default withTranslation([NAMESPACES.RPS])(GlobalLoadingHands);
