import { ReactElement, useEffect } from 'react';
import { GameState } from '@cryptoskill/rps-contract';

import { getGameStateSelector, getRenderingGameTime } from '../../../../redux/selectors/rpsSelector';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setRenderingGameTime } from '../../../../redux/reducers/rpsReducer/rpsReducer';
import { fetchGameTimeout } from '../../../../redux/actions/rpsActions/rpsActions';

interface GameTimerProps {
    children: ReactElement;
}

export const GAME_TIME = 30;

export const GameTimer = ({ children }: GameTimerProps) => {
    const dispatch = useAppDispatch();
    const game = useAppSelector(getGameStateSelector);
    const renderingGameTime = useAppSelector(getRenderingGameTime);

    const shouldStopTimer = [
        GameState.BOTH_PLAYERS_PLAYED,
        GameState.DRAW,
        GameState.PLAYER_ONE_WON,
        GameState.PLAYER_TWO_WON
    ].includes(game.state);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval> = null;

        if (shouldStopTimer) {
            return;
        }

        if (renderingGameTime === 0) {
            dispatch(fetchGameTimeout());
            return;
        }

        const updateTimer = () => {
            const now = Date.now();
            const unixTimeStamp = Math.floor(now / 1000);
            const remainingTime = Math.max(GAME_TIME - (unixTimeStamp - (game.startTime || 0)), 0);
            dispatch(setRenderingGameTime(remainingTime));

            if ((remainingTime === 0 || shouldStopTimer) && interval) {
                clearInterval(interval);
            }
        };

        updateTimer();

        interval = setInterval(updateTimer, 1000);

        return () => clearInterval(interval);
    }, [dispatch, game.startTime, renderingGameTime, shouldStopTimer]);

    return children;
};

export default GameTimer;