import {
    CategoryV2,
    PublicGameData,
    QueuePositionData,
    SuccessfulJoinGameResponseBody,
    SuccessfullCustomTokenResponse
} from '@cryptoskill/rps-contract';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
    fetchCustomToken,
    fetchFirestoreActiveQueuePositions,
    fetchFirestoreActiveUserByCategories,
    fetchGameJoin
} from '../../actions/rpsActions/rpsActions';
import { GAME_TIME } from '../../../components/game/game-rendering/game-timer/GameTimer';

export enum SubscriptionsStates {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
    NONE = 'none'
}

export interface Categories {
    [categoryName: string]: {
        levels: string[];
        levelsDetails: {
            [level: string]: {
                name: string;
                active: boolean;
                botReservations: number;
                activeUsers: number;
            }
        }
    }
}

interface RpsState {
    customToken: string;
    isUserInPlay: boolean;

    categoriesSubscriptionState: SubscriptionsStates;
    categories: Categories;

    queuePositionSubscriptionState: SubscriptionsStates;
    queuePosition: QueuePositionData;
    queuePositionId: QueuePositionData['id']

    gameStateSubscriptionState: SubscriptionsStates;
    gameState: PublicGameData;

    gameRendering: {
        gameTime: number;
        previewHand: string;
    }
}

// Stryker disable next-line ObjectLiteral
const initialState: RpsState = {
    customToken: null,
    isUserInPlay: false,

    categoriesSubscriptionState: SubscriptionsStates.NONE,
    categories: null,

    queuePositionSubscriptionState: SubscriptionsStates.NONE,
    queuePosition: null,
    queuePositionId: null,

    gameStateSubscriptionState: SubscriptionsStates.NONE,
    gameState: null,

    gameRendering: {
        gameTime: 30,
        previewHand: ''
    }
};

export const rpsReducer = createSlice({
    name: 'rps',
    initialState,
    reducers: {
        setCategoriesSubscriptionState: (state, action: PayloadAction<SubscriptionsStates>) => {
            if (state.categoriesSubscriptionState !== action.payload) {
                state.categoriesSubscriptionState = action.payload;
            }
        },
        setCategories: (state, action: PayloadAction<CategoryV2>) => {
            const categoryName = action.payload.name;

            return {
                ...state,
                categories: {
                    ...state.categories,
                    [categoryName]: {
                        ...state.categories?.[categoryName],
                        levels: Array.from(new Set([...state.categories?.[categoryName]?.levels || [], action.payload.level]))
                            .sort((a, b) => parseFloat(a) - parseFloat(b)),
                        levelsDetails: {
                            ...state.categories?.[categoryName]?.levelsDetails,
                            [action.payload.level]: {
                                ...state.categories?.[categoryName]?.levelsDetails?.[action.payload.level],
                                name: categoryName,
                                active: action.payload.active,
                                botReservations: action.payload.botReservations
                            }
                        }
                    }
                }
            };
        },
        setQueuePositionSubscriptionState: (state, action: PayloadAction<SubscriptionsStates>) => {
            if (state.queuePositionSubscriptionState !== action.payload) {
                state.queuePositionSubscriptionState = action.payload;
            }
        },
        setQueuePositionData: (state, action: PayloadAction<QueuePositionData>) => ({
            ...state,
            isUserInPlay: true, // TODO: Fix this to prevent redirecting to game page when user is not in queue or has cancelled the game
            queuePosition: action.payload
        }),
        clearQueuePositionData: state => ({
            ...state,
            isUserInPlay: false,
            queuePosition: null,
            queuePositionId: null
        }),
        setGameStateSubscriptionState: (state, action: PayloadAction<SubscriptionsStates>) => {
            if (state.gameStateSubscriptionState !== action.payload) {
                state.gameStateSubscriptionState = action.payload;
            }
        },
        setGameState: (state, action: PayloadAction<PublicGameData>) => ({
            ...state,
            gameState: action.payload
        }),
        clearGameState: state => ({
            ...state,
            gameState: null
        }),
        setCancelGame: state => ({
            ...state,
            isUserInPlay: false,
            queuePositionSubscriptionState: null,
            queuePosition: null,
            queuePositionId: null,
            gameStateSubscriptionState: null,
            gameState: null
        }),
        setRenderingGameTime: (state, action) => ({
            ...state,
            gameRendering: {
                ...state.gameRendering,
                gameTime: action.payload
            }
        }),
        setPreviewHand: (state, action) => ({
            ...state,
            gameRendering: {
                ...state.gameRendering,
                previewHand: action.payload
            }
        })
    },
    extraReducers: builder => {
        builder.addCase(fetchCustomToken.fulfilled, (state, action) => ({
            ...state,
            customToken: (action.payload as SuccessfullCustomTokenResponse).customToken || null
        }));
        builder.addCase(fetchFirestoreActiveQueuePositions.fulfilled, (state, action) => {
            if ((action.payload as QueuePositionData[]).length > 0) {
                return {
                    ...state,
                    isUserInPlay: true,
                    queuePositionId: (action.payload as QueuePositionData[])[0].id
                };
            }
        });
        builder.addCase(fetchFirestoreActiveUserByCategories.fulfilled, (state, action) => ({
            ...state,
            categories: {
                ...state.categories,
                [action.payload.selectedCurrency.toUpperCase()]: {
                    ...state.categories[action.payload.selectedCurrency.toUpperCase()],
                    levelsDetails: {
                        ...state.categories[action.payload.selectedCurrency.toUpperCase()].levelsDetails,
                        ...Object.keys(action.payload.gamesCount).reduce((acc, level) => ({
                            ...acc,
                            [level]: {
                                ...state.categories[action.payload.selectedCurrency.toUpperCase()].levelsDetails[level],
                                activeUsers: action.payload.gamesCount[level]
                            }
                        }), {})
                    }
                }
            }
        }));
        builder.addCase(fetchGameJoin.fulfilled, (state, action) => ({
            ...state,
            isUserInPlay: true,
            queuePositionId: (action.payload as SuccessfulJoinGameResponseBody).queuePositionIdentifier,
            queuePosition: null,
            queuePositionSubscriptionState: null,
            gameState: null,
            gameStateSubscriptionState: null,
            gameRendering: {
                gameTime: GAME_TIME,
                previewHand: '',
                selectedHand: ''
            }
        }));
    }
});

export const {
    setCategoriesSubscriptionState,
    setCategories,
    setQueuePositionSubscriptionState,
    setQueuePositionData,
    clearQueuePositionData,
    setGameStateSubscriptionState,
    setGameState,
    clearGameState,
    setCancelGame,
    setRenderingGameTime,
    setPreviewHand
} = rpsReducer.actions;

export const rpsReducerInitialState = rpsReducer.getInitialState();

export default rpsReducer.reducer;
