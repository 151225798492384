import { ReactElement, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getDemoIsEndGame, getDemoTime } from '../../../redux/selectors/demoRpsSelector';
import { decrementDemoTimer, setDemoTimeout } from '../../../redux/reducers/demoRpsReducer/demoRpsReducer';

interface DemoGameTimerProps {
    children: ReactElement;
}

export const DemoGameTimer = ({ children }: DemoGameTimerProps) => {
    const dispatch = useAppDispatch();
    const demoGameTime = useAppSelector(getDemoTime);
    const isEndGame = useAppSelector(getDemoIsEndGame);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval> = null;

        if (demoGameTime === 0) {
            dispatch(setDemoTimeout());
            return;
        }

        if (isEndGame) {
            return;
        }

        interval = setInterval(() => {
            dispatch(decrementDemoTimer());

            if ((demoGameTime === 0) && interval) {
                clearInterval(interval);
            }
            if (isEndGame && interval) {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [dispatch, demoGameTime, isEndGame]);

    return children;
};

export default DemoGameTimer;