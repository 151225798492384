import { CompiledMessage } from '@cryptoskill/rps-contract';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum RequestStatesEnum {
    PENDING = 'pending',
    REJECTED = 'rejected',
    FULFILLED = 'fulfilled'
}

export interface BaseRequestState {
    state: RequestStatesEnum;
    viewed: boolean;
}

export interface RequestStatePending extends BaseRequestState {
    state: RequestStatesEnum.PENDING;
}

export interface RequestStateFulfilledOrRejected extends BaseRequestState {
    state: RequestStatesEnum.REJECTED | RequestStatesEnum.FULFILLED;
    message: CompiledMessage;
}

type RequestStateTypes = RequestStatePending | RequestStateFulfilledOrRejected;


export type RequestStates = Record<string | string, RequestStateTypes>;

const requestStatesSlice = createSlice({
    name: 'requestStates',
    initialState: {},
    reducers: {
        requestStateViewed: (state, action: PayloadAction<string>) => {
            const requestName = action.payload;
            if (state[requestName]) {
                state[requestName] = {
                    ...state[requestName],
                    viewed: true
                };
            }
        },
        clearState: (state, action: PayloadAction<string>) => {
            const requestName = action.payload;
            if (state[requestName]) {
                state[requestName] = {};
            }
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            (action: PayloadAction<any>) => /(.*)\/(pending|rejected|fulfilled)/.test(action.type),
            (state, action: PayloadAction<any>) => {
                const matches = /(.*)\/(pending|rejected|fulfilled)/.exec(action.type);

                if (matches) {
                    const [, requestName, requestState] = matches;

                    if (requestState === RequestStatesEnum.PENDING) {
                        state[requestName] = {
                            ...state[requestName],
                            state: requestState,
                            viewed: false
                        };

                        return;
                    }

                    if (requestState === RequestStatesEnum.REJECTED) {
                        state[requestName] = { 
                            state: requestState,
                            message: action.payload?.response?.data?.message || null,
                            viewed: false
                        };

                        return;
                    }
                    
                    state[requestName] = { 
                        state: requestState,
                        message: action.payload?.message || null,
                        viewed: false
                    };
                }
            }
        );
    }
});

export const {
    requestStateViewed,
    clearState
} = requestStatesSlice.actions;

export default requestStatesSlice.reducer;