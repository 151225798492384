import { useMemo } from 'react';

import { Categories } from '../../../redux/reducers/rpsReducer/rpsReducer';

export interface TableDataType {
    id: string;
    coin: string;
    amount: string;
    activePlayers: number;
}

export const useTableData = (categories: Categories, selectedCurrency: string) =>
    useMemo<TableDataType[]>(() => 
        categories[selectedCurrency].levels.map((level, index) => {
            return {
                id: `${index}-${level}-${selectedCurrency}`,
                coin: selectedCurrency,
                amount: level,
                activePlayers: categories[selectedCurrency].levelsDetails[level].activeUsers || 0,
                active: categories[selectedCurrency].levelsDetails[level].active || false
            };
        }).filter(level => level.active), [categories, selectedCurrency]);
