import { useEffect } from 'react';

import { useAppDispatch } from '../../redux/hooks';
import { fetchCustomToken } from '../../redux/actions/rpsActions/rpsActions';

export const useFetchCustomToken = (userId: string) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (userId) {
            dispatch(fetchCustomToken());
        }
    }, [userId, dispatch]);
};