import { CategoryV2 } from '@cryptoskill/rps-contract';
import { QueryDocumentSnapshot, collection, query, where, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';

import { rpssFirestore } from '../../firebase/firebase';
import { useAppDispatch } from '../../redux/hooks';
import { SubscriptionsStates, setCategories, setCategoriesSubscriptionState } from '../../redux/reducers/rpsReducer/rpsReducer';

export const useCategorySubscription = (currency: string) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (currency) {
            dispatch(setCategoriesSubscriptionState(SubscriptionsStates.LOADING));

            const collectionRef = collection(rpssFirestore, 'categoriesV2');

            const queryRef = query(collectionRef, where('name', '==', currency.toUpperCase()));

            const unsubscribe = onSnapshot(queryRef,
                querySnapshot => {
                    querySnapshot.forEach((queuePositionDocument: QueryDocumentSnapshot<CategoryV2>) => {
                        dispatch(setCategories(queuePositionDocument.data()));
                    });

                    dispatch(setCategoriesSubscriptionState(SubscriptionsStates.SUCCESS));
                },
                () => {
                    dispatch(setCategories(null));
                    dispatch(setCategoriesSubscriptionState(SubscriptionsStates.ERROR));
                });

            return () => {
                dispatch(setCategoriesSubscriptionState(SubscriptionsStates.NONE));
                unsubscribe();
            };
        }
    }, [currency, dispatch]);
};
