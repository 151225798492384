import { QueryDocumentSnapshot, collection, onSnapshot, query, where } from 'firebase/firestore';
import { QueuePositionData } from '@cryptoskill/rps-contract';
import { useEffect } from 'react';

import { rpssFirestore } from '../../firebase/firebase';
import { useAppDispatch } from '../../redux/hooks';
import { SubscriptionsStates, setQueuePositionData, setQueuePositionSubscriptionState } from '../../redux/reducers/rpsReducer/rpsReducer';

export const useQueuePositionSubscription = (userId: string, queuePositionId: string) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!queuePositionId) return;

        dispatch(setQueuePositionSubscriptionState(SubscriptionsStates.LOADING));

        const collectionRef = collection(rpssFirestore, 'queuePositions');

        const queryRef = query(
            collectionRef,
            where('id', '==', queuePositionId),
            where('playerId', '==', userId)
        );

        const unsubscribe = onSnapshot(queryRef,
            querySnapshot => {
                querySnapshot.forEach((queuePositionDocument: QueryDocumentSnapshot<QueuePositionData>) => {
                    dispatch(setQueuePositionData(queuePositionDocument.data()));
                    dispatch(setQueuePositionSubscriptionState(SubscriptionsStates.SUCCESS));
                });
            },
            () => {
                dispatch(setQueuePositionData(null));
                dispatch(setQueuePositionSubscriptionState(SubscriptionsStates.ERROR));
            });

        return () => {
            dispatch(setQueuePositionSubscriptionState(SubscriptionsStates.NONE));
            unsubscribe();
        };
    }, [userId, queuePositionId, dispatch]);
};
