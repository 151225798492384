import { useEffect } from 'react';

export type OnMessage = (event: MessageEvent) => void;

export const useParentEventSubscription = (onMessage: OnMessage) => {
    useEffect(() => {
        const handleParentData = event => {
            // Add validations if needed e.g: event.origin !== 'https://www.cryptoskill.com'
            onMessage(event);
        };

        if (window.addEventListener) {
            window.addEventListener('message', handleParentData);

            return () => window.removeEventListener('message', handleParentData);
        } else {
            console.warn('Browser does not support addEventListener');
        }
    }, [onMessage]);
};
