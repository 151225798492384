import { Container } from 'react-bootstrap';
import { MouseEvent, ReactElement } from 'react';

import { SIZES } from '../hand/Hand';

export interface HandContainerProps {
    size?: SIZES;
    className?: string;
    key: string;
    children: React.ReactNode;
    handlers: {
        onClick?: (event: MouseEvent<HTMLElement>) => void;
        onMouseOver?: (event: MouseEvent<HTMLElement>) => void;
        onMouseOut?: () => void;
    };
}

export const HandContainer = ({ className = '', size, children, handlers }: HandContainerProps): ReactElement => (
    <Container
        style={{ maxWidth: size, padding: 0 }}
        className={className}
        {...handlers}>
        {children}
    </Container>
);

export default HandContainer;