import { ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import { Stack } from 'react-bootstrap';

import { useSendParentHeight } from '../../../hooks/iframe/useSendParentHeight';

import './global-container.scss';

interface GlobalContainerProps {
    children: ReactElement;
}

export const GlobalContainer = ({ children }: GlobalContainerProps): ReactElement => {
    console.log('GlobalContainer');
    const containerRef = useSendParentHeight();

    const isDevelopment = false; //process.env.NODE_ENV === 'development';
    const rootContainerClassName = isDevelopment ? 'dev-background d-flex justify-content-center align-items-center' : 'app';
    const appContainerClassName = isDevelopment ? 'dev-app w-75 m-5' : '';

    return (
        <Container ref={containerRef} fluid className={rootContainerClassName}>
            <Container fluid className={appContainerClassName}>
                <Stack>
                    {children}
                </Stack>
            </Container>
        </Container>
    );
};

export default GlobalContainer;