
import { ReactElement, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';

import { NAMESPACES } from '../../../i18n';
import { useAppSelector } from '../../../redux/hooks';
import { getQueuePositionIdSelector, getQueuePositionSelector, getQueuePositionSubscriptionState } from '../../../redux/selectors/rpsSelector';
import { useQueuePositionSubscription } from '../../../hooks/rps/useQueuePositionSubscription';
import { SubscriptionsStates } from '../../../redux/reducers/rpsReducer/rpsReducer';
import { getUserData } from '../../../redux/selectors/commonSelector';

interface QueuePositionSubscriptionProps {
    children: ReactElement,
    t: (key: string, options?: { ns: string }) => string;
}

export const QueuePositionSubscription = ({ children, t }: QueuePositionSubscriptionProps): ReactElement => {
    const user = useAppSelector(getUserData);
    const queuePositionId = useAppSelector(getQueuePositionIdSelector);
    const queuePosition = useAppSelector(getQueuePositionSelector);
    const queuePositionSubscriptionState = useAppSelector(getQueuePositionSubscriptionState);

    useQueuePositionSubscription(user.userId, queuePositionId);

    const memoizedChildren = useMemo(() => children, [children]);

    if (!queuePositionId) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.queue-position-subscription.queue-not-found')}</h6>
            </Row>
        );
    }

    if (queuePositionSubscriptionState === SubscriptionsStates.LOADING) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.queue-position-subscription.loading')}</h6>
            </Row>
        );
    }

    if (queuePositionSubscriptionState === SubscriptionsStates.ERROR) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.queue-position-subscription.error')}</h6>
            </Row>
        );
    }

    if (queuePositionSubscriptionState === SubscriptionsStates.SUCCESS && !queuePosition) {
        return (
            <Row className='m-1 mb-3'>
                <h6 className='text-center'>{t('game.queue-position-subscription.empty')}</h6>
            </Row>
        );
    }

    return queuePositionSubscriptionState === SubscriptionsStates.SUCCESS && memoizedChildren;
};

export default withTranslation([NAMESPACES.RPS])(QueuePositionSubscription);
