import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';

import {
    EMPTY_HAND,
    HANDS,
    HANDS_STATUS,
    MAX_TIME_LOADING,
    MIN_TIME_LOADING,
    TIME_LOADING_SELECTED_HAND
} from '../../../constants/hand';
import { setIntervalGenerateRandomHands } from '../../../utils/utils';
import { HandContainer } from '../hand-container/HandContainer';

import './hand.scss';
import './hands-loading-animations.scss';
import './selected-hand-animation.scss';
import './hand-hover-animation.scss';


export enum SIZES {
    sm = '10em'
}

export interface HandProps {
    size?: SIZES;
    loading?: boolean;
    reverse?: boolean;
    showHand?: string | null;
    statusHand?: string | null;
    handleOnClick?: (event: MouseEvent<HTMLElement>) => void;
    handleOnHover?: (event: MouseEvent<HTMLElement>) => void;
    handleOnOut?: () => void;
}

const hands = Object.keys(HANDS);

const Hand = ({
    size,
    loading,
    reverse,
    showHand,
    statusHand,
    handleOnClick,
    handleOnHover,
    handleOnOut }: HandProps): ReactElement | null => {

    const isStatusHandSelected = statusHand === HANDS_STATUS.selected;
    const minTimeAnimation = loading && isStatusHandSelected ? TIME_LOADING_SELECTED_HAND : MIN_TIME_LOADING;
    const maxTimeAnimation = loading && isStatusHandSelected ? TIME_LOADING_SELECTED_HAND : MAX_TIME_LOADING;

    const handsMaxLength = hands.length;

    const shouldReverse = reverse ? '-reverse' : '';

    const [randomHand, setRandomHand] = useState<string>(hands[Math.floor(Math.random() * handsMaxLength)]);

    useEffect(() => {
        if (loading) {
            const interval = setIntervalGenerateRandomHands(handsMaxLength, randomHand, hands, setRandomHand, maxTimeAnimation, minTimeAnimation);

            return () => clearInterval(interval);
        }
    }, [handsMaxLength, randomHand, maxTimeAnimation, minTimeAnimation, loading]);

    const handlers = {
        onClick: handleOnClick,
        onMouseOver: handleOnHover,
        onMouseOut: handleOnOut
    };

    if (showHand && !loading) {
        const status = statusHand ? `-${statusHand}` : '';

        return (
            <HandContainer
                key={randomHand}
                className={`animated-selected-hand${shouldReverse}${status}`}
                size={size}
                handlers={handlers}>
                <Image fluid alt={showHand} src={HANDS[showHand]} />
            </HandContainer>
        );
    }

    if (loading && isStatusHandSelected) {
        return (
            <HandContainer
                key={randomHand}
                size={size}
                className={`animated-hands${shouldReverse}`}
                handlers={handlers}>
                <Image fluid alt={randomHand} src={HANDS[randomHand]} />
            </HandContainer>
        );
    }

    if (loading) {
        const isReversedLoading = Math.floor(Math.random() * 2) ? '-reverse' : '';

        return (
            <HandContainer
                key={randomHand}
                size={size}
                className={`animated-hands${isReversedLoading}`}
                handlers={handlers}>
                <Image fluid alt={randomHand} src={HANDS[randomHand]} />
            </HandContainer>
        );
    }

    return (
        <HandContainer
            key={randomHand}
            size={size}
            handlers={handlers}>
            <Image fluid alt="empty" src={EMPTY_HAND} />
        </HandContainer>
    );
};

export default Hand;