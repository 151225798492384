import { ReactElement } from 'react';

import CurrencyVerifier from './currency-verifier/CurrencyVerifier';

interface RpsValidationsProps {
    children: ReactElement
}

export const RpsValidations = ({ children }: RpsValidationsProps): ReactElement => {
    return (
        <CurrencyVerifier>
            {children}
        </CurrencyVerifier>
    );
};

export default RpsValidations;
