import { ReactElement } from 'react';

import GameHeader from '../game-header/GameHeader';
import { useAppSelector } from '../../../../redux/hooks';
import { getGameStateSelector, getRenderingGameTime } from '../../../../redux/selectors/rpsSelector';
import { getUserData } from '../../../../redux/selectors/commonSelector';

export const GameHeaderController = (): ReactElement => {
    const user = useAppSelector(getUserData);
    const publicGame = useAppSelector(getGameStateSelector);
    const renderingGameTime = useAppSelector(getRenderingGameTime);

    const { player1Name, player2Name } = user.userId === publicGame.playerOneId ?
        { player1Name: publicGame.playerOneNameAtStartOfGame, player2Name: publicGame.playerTwoNameAtStartOfGame } :
        { player1Name: publicGame.playerTwoNameAtStartOfGame, player2Name: publicGame.playerOneNameAtStartOfGame };

    return (
        <GameHeader
            player1Name={player1Name}
            player2Name={player2Name}
            renderingGameTime={renderingGameTime}
        />
    );
};

export default GameHeaderController;