import { ReactElement } from 'react';

import useRpsNotifications from '../../../hooks/notifications/useRpsNotifications';

interface GlobaHooks {
    children: React.ReactNode;
}

export const GlobalHooks = ({ children }: GlobaHooks): ReactElement => {
    console.log('GlobalHooks');
    useRpsNotifications();

    return <>{children}</>;
};

export default GlobalHooks;
