import Table from 'react-bootstrap/Table';

import LobbyTableHeader from './lobby-table-header/LobbyTableHeader';
import LobbyTableBody from './lobby-table-body/LobbyTableBody';

import { useAppSelector } from '../../../redux/hooks';
import { getCategories } from '../../../redux/selectors/rpsSelector';
import { selectedCurrencySelector } from '../../../redux/selectors/commonSelector';
import { useTableData } from '../../../hooks/rps/utils/useTableData';

const LobbyTable = () => {
    const selectedCurrency = useAppSelector(selectedCurrencySelector);
    const categories = useAppSelector(getCategories);

    const headerValues = ['BET_AMOUNT_LABEL', 'ACTIVE_PLAYERS_LABEL'];
    const headerKeys = ['amount', 'activePlayers'];

    const tableData = useTableData(categories, selectedCurrency.toLocaleUpperCase());

    return (
        <Table striped responsive borderless>
            <LobbyTableHeader headerValues={headerValues} />
            <LobbyTableBody
                categoryName={selectedCurrency.toUpperCase()}
                tableData={tableData}
                headerKeys={headerKeys}
                selectedCoin={selectedCurrency} />
        </Table>
    );
};

export default LobbyTable;