import DemoGameTimer from './demo-game-timer/DemoGameTimer';
import DemoGameTimeout from './demo-game-timeout/DemoGameTimeout';
import DemoGameRendering from './demo-game-rendering/DemoGameRendering';
import DemoGameHeaderController from './demo-game-header-controller/DemoGameHeaderController';
import DemoGameBodyController from './demo-game-body-controller/DemoGameBodyController';
import DemoGameFooterController from './demo-game-footer-controller/DemoGameFooterController';

export const DemoGame = () => {
    return (
        <DemoGameTimer>
            <DemoGameTimeout>
                <DemoGameRendering>
                    <>
                        <DemoGameHeaderController />
                        <DemoGameBodyController />
                        <DemoGameFooterController />
                    </>
                </DemoGameRendering>
            </DemoGameTimeout>
        </DemoGameTimer>
    );
};

export default DemoGame;