import { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import { NAMESPACES } from '../../../i18n';
import { useCustomAuth } from '../../../hooks/auth/useCustomAuth';

interface RpsCustomAuthenticationProps {
    children: ReactElement,
    t: (key: string, options?: { ns: string }) => string;
}

export const RpsCustomAuthentication = ({ children, t }: RpsCustomAuthenticationProps): ReactElement => {
    const { loading, error, success } = useCustomAuth();

    if (loading) {
        return (
            <Row className='m-1 mb-2'>
                <h6 className='text-center'>{t('authentication.rps-auth.loading')}</h6>
            </Row>
        );
    }

    if (error) {
        return (
            <Row className='m-1 mb-2'>
                <h6 className='text-center'>{t('authentication.rps-auth.error')}</h6>
                <Button
                    className='shadow-none'
                    variant='info'
                    onClick={() => window.location.reload()}
                >
                    {t('authentication.rps-auth.button')}
                </Button>
            </Row>
        );
    }

    return success && children;
};

export default withTranslation([NAMESPACES.RPS])(RpsCustomAuthentication);
