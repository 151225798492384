import ReactDOM from 'react-dom/client';
import Axios from 'axios';

import Providers from './components/providers/Providers';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Globals from './components/globals/Globals';
import RpsAuthentication from './components/rps-authentication/RpsAuthentication';
import RpsGameRouter from './components/rps-game-router/RpsGameRouter';
import RpsValidations from './components/rps-validations/RpsValidations';

import './styles/global.scss';

Axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Providers>
        <Globals>
            <RpsAuthentication>
                <RpsValidations>
                    <RpsGameRouter />
                </RpsValidations>
            </RpsAuthentication>
        </Globals>
    </Providers>
);

serviceWorkerRegistration.register();

reportWebVitals();
