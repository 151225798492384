import QueuePositionSubscription from './queue-position-subscription/QueuePositionSubscription';
import QueuePositionStatesHandler from './queue-position-states-handler/QueuePositionStatesHandler';
import GameSubscription from './game-subscription/GameSubscription';
import GameStatesHandler from './game-states-handler/GameStatesHandler';
import GameRendering from './game-rendering/GameRendering';

export const Game = () => {
    return (
        <QueuePositionSubscription>
            <QueuePositionStatesHandler>
                <GameSubscription>
                    <GameStatesHandler>
                        <GameRendering />
                    </GameStatesHandler>
                </GameSubscription>
            </QueuePositionStatesHandler>
        </QueuePositionSubscription>
    );
};

export default Game;