import { ReactElement } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getDemoIsTimeout } from '../../../redux/selectors/demoRpsSelector';
import { NAMESPACES } from '../../../i18n';
import { clearDemoGame, restartDemoGame } from '../../../redux/reducers/demoRpsReducer/demoRpsReducer';

interface DemoGameTimeoutProps {
    children: ReactElement;
    t: (key: string, options?: { ns: string }) => string;
}

export const DemoGameTimeout = ({ children, t }: DemoGameTimeoutProps) => {
    const dispatch = useAppDispatch();
    const isTimeout = useAppSelector(getDemoIsTimeout);

    if (isTimeout) {
        return (
            <Row className="m-2">
                <Row className='text-center'>
                    <h6 className='text-center'>{t('game.game-states-handler.timeout')}</h6>
                </Row>
                <Col className='m-1'>
                    <Button
                        className='shadow-none w-100'
                        variant='info'
                        onClick={() => {
                            dispatch(clearDemoGame());
                        }}
                    >
                        {t('game.game-states-handler.return-lobby')}
                    </Button>
                </Col>
                <Col className='m-1'>
                    <Button
                        className='shadow-none w-100'
                        variant='info'
                        onClick={() => dispatch(restartDemoGame())}
                    >
                        {t('game.game-states-handler.rematch')}
                    </Button>
                </Col>
            </Row>
        );
    }

    return children;
};

export default withTranslation([NAMESPACES.RPS])(DemoGameTimeout);