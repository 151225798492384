import { ReactElement, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';

import { POST_MESSAGE_TYPES } from '../../../constants/postMessagesTypes';
import { NAMESPACES } from '../../../i18n/constants';

interface GlobalLoadingProps {
    children: ReactElement;
    t: (key: string, options?: { ns: string }) => string;
}

export const GlobalLoading = ({ children, t }: GlobalLoadingProps): ReactElement => {
    console.log('GlobalLoading');
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        setPageLoaded(true);
            
        if (window.parent) {
            console.log('RPS GlobalLoading Sending Iframe Loaded Message');
            window.parent.postMessage({ type: POST_MESSAGE_TYPES.IFRAME_LOADED }, '*');
        }

        return () => {
            if (window.parent) {
                window.parent.postMessage({ type: POST_MESSAGE_TYPES.IFRAME_UNLOADED }, '*');
            }
        };
    }, []);

    console.log('GlobalLoading pageLoaded: ', pageLoaded);

    if (!pageLoaded) {
        return (
            <Row className='m-1 mb-2'>
                <h6 className='text-center'>{t('global.loading.title')}</h6>
            </Row>
        );
    }

    return children;
};

export default withTranslation([NAMESPACES.RPS])(GlobalLoading);
