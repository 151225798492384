import { configureStore } from '@reduxjs/toolkit';

import commonReducer from './reducers/commonReducer/commonReducer';
import requestStatesReducer from './reducers/requestStateReducer/requestStateReducer';
import rpsReducer from './reducers/rpsReducer/rpsReducer';
import displayReducer from './reducers/displayReducer/displayReducer';
import demoRpsReducer from './reducers/demoRpsReducer/demoRpsReducer';

export const store = configureStore({
    reducer: {
        common: commonReducer,
        rps: rpsReducer,
        demoRps: demoRpsReducer,
        requestStates: requestStatesReducer,
        display: displayReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
    devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;