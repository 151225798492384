import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { signInWithFirebaseCustomToken } from '../../actions/authActions/authActions';

interface Metadata {
    isLogged: boolean;
    userId: string | null;
    username: string | null;
    selectedCoin: string | null;
    activated: boolean;
}

export interface CommonReducerState extends Metadata {
    waitingForParentMetadata: boolean;
    isUserSignedWithCustomToken: boolean;
    isUserCompletelyLogged: boolean;
}

const initialState: CommonReducerState = {
    isUserCompletelyLogged: false,
    waitingForParentMetadata: true,
    isLogged: false,
    userId: null,
    username: null,
    selectedCoin: null,
    isUserSignedWithCustomToken: false,
    activated: false
};

export const commonReducer = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setMetadata: (state, action: PayloadAction<Metadata>) => {
            Object.assign(state, action.payload);
            state.waitingForParentMetadata = false;
            state.isUserCompletelyLogged = action.payload.isLogged && state.isUserSignedWithCustomToken;
        }
    },
    extraReducers: builder => {
        builder.addCase(signInWithFirebaseCustomToken.fulfilled, state => {
            state.isUserSignedWithCustomToken = true;
            state.isUserCompletelyLogged = state.isLogged;
        });
        builder.addCase(signInWithFirebaseCustomToken.rejected, state => {
            state.isUserSignedWithCustomToken = false;
            state.isUserCompletelyLogged = false;
        });
    }
});

export const { setMetadata } = commonReducer.actions;

export const commonReducerInitialState = commonReducer.getInitialState();

export default commonReducer.reducer;
