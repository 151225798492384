import { ReactElement, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

import Hand from '../../../core/hand/Hand';

import './game-body.scss';

interface GameBodyProps {
    shouldLoadPlayer1: boolean;
    statusPlayer1: string;
    showHandPlayer1: string;
    shouldLoadPlayer2: boolean;
    statusPlayer2: string;
    showHandPlayer2: string;
}

export const GameBody = ({
    shouldLoadPlayer1,
    statusPlayer1,
    showHandPlayer1,
    shouldLoadPlayer2,
    statusPlayer2,
    showHandPlayer2
}: GameBodyProps): ReactElement => {
    const memoPlayer1Hand = useMemo(() => (
        <Hand loading={shouldLoadPlayer1} statusHand={statusPlayer1} showHand={showHandPlayer1} />
    ), [shouldLoadPlayer1, statusPlayer1, showHandPlayer1]);

    const memoPlayer2Hand = useMemo(() => (
        <Hand loading={shouldLoadPlayer2} statusHand={statusPlayer2} showHand={showHandPlayer2} reverse />
    ), [shouldLoadPlayer2, statusPlayer2, showHandPlayer2]);

    return (
        <Row className='mt-4 mb-4 flex-nowrap'>
            <Col className="m-4">{memoPlayer1Hand}</Col>
            <Col lg="1" className="w-auto">
                <div className="d-flex justify-content-center h-100">
                    <div className="vr text-black rounded divider"></div>
                </div>
            </Col>
            <Col className="m-4">{memoPlayer2Hand}</Col>
        </Row>
    );
};

export default GameBody;